.holidaysListContainer {
  overflow-x: auto;
  margin-right: auto !important;
  margin-left: auto !important;
  /* margin-top: 10px !important;
  padding: 10px;
  margin: 10px; */
}

.HolidayListTableContainer {
  max-height: 220px !important;
 border: 1px solid gray;
}

.title {
  margin-top: 20px;
  text-align: center;
}

tr {
  height: 40px !important;
}

th {
  max-width: 125px;
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}

.holidaysListHeading {
  font-weight: bold !important;
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .holidaysListHeading {
    font-size: calc(12px + 0.2rem) !important;
  }

  .holidaysListHeadingData {
    font-size: calc(12px + 0.1rem) !important;
  }

  .lmsLeaveTabsCards {
    height: 100% !important;
  }

  .css-6l8dcw {
    margin-bottom: 18px !important;
  }

  #year-label,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: calc(12px + 0.1rem) !important;
  }

  label + .css-ylsrhm-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: unset !important;
    min-width: 50px !important;
  }

  .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    font-size: calc(12px + 0.1rem) !important;
    padding-right: 0 !important;
  }

  .holidaysListHeading {
    font-weight: bold !important;
  }

  .HolidayListTableContainer {
    max-height: 40vh !important;
    border:"1px solid gray"
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .holidaysListHeading {
    font-size: calc(12px + 0.4rem) !important;
  }

  .holidaysListHeadingData {
    font-size: calc(12px + 0.2rem) !important;
  }

  .lmsLeaveTabsCards {
    height: 100% !important;
  }

  .css-6l8dcw {
    margin-bottom: 25px !important;
  }

  #year-label,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: calc(12px + 0.3rem) !important;
  }

  label + .css-ylsrhm-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 30px !important;
    min-width: 200px !important;
  }

  .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    font-size: calc(12px + 0.8rem) !important;
    padding-top: 10px;
    padding-right: 0 !important;
  }

  .holidaysListHeading {
    font-weight: bold !important;
  }

  .HolidayListTableContainer {
    max-height: 45vh !important;
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
}

@media screen and (min-width: 601px) and (max-width: 999px) {
  .HolidayListTableContainer {
    max-height: 40vh !important;
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
}

@media screen and (max-width: 600px) {
  .holidaysListHeadingData {
    font-size: calc(12px + 0.006rem) !important;

    min-width: 50px !important;
  }

  .holidaysListHeading {
    font-size: calc(12px + 0.008rem) !important;

    min-width: 50px !important;
  }

  .css-ylsrhm-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    font-size: 0.6rem !important;
  }

  #year-label {
    font-size: calc(12px + 0.008rem) !important;
  }

  .MuiInputBase-root
    .MuiInput-root
    .MuiInput-underline
    .MuiInputBase-colorPrimary
    .MuiInputBase-formControl
    .MuiInputBase-sizeSmall
    .css-ylsrhm-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 10px !important;
  }

  .HolidayListTableContainer {
    max-height: 95% !important;
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
}

@media screen and (min-width: 2000px) {
  .holidaysListHeading {
    font-size: calc(12px + 0.8rem) !important;
  }

  .holidaysListHeadingData {
    font-size: calc(12px + 0.6rem) !important;
  }

  .lmsLeaveTabsCards {
    height: 100% !important;
  }

  .css-6l8dcw {
    margin-bottom: 25px !important;
  }

  /* #year-label,
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-size: calc(12px + 1.6rem) !important;
  } */

  label + .css-ylsrhm-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 50px !important;
    min-width: 250px !important;
  }

  .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    font-size: calc(12px + 0.8rem) !important;
    padding-top: 20px;
    padding-right: 0 !important;
  }

  .holidaysListHeading {
    font-weight: bold !important;
  }

  .HolidayListTableContainer {
    max-height: 35vh !important;
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
}
