.teamsContainer {
 border: 1px solid gray;
}

.title {
  text-align: center;
}

tr {
  height: 40px !important;
}

th {
  max-width: 300px;
  min-width: 160px !important;
  padding: 0 !important;
  overflow-x: hidden;
  white-space: noWrap;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
  text-transform: capitalize;
}

.teamLeavesHeading {
  font-weight: bold !important;
}

@media screen and (max-width: 600px) {
  .teamLeavesHeading {
    font-size: calc(12px + 0.008rem) !important;
    font-weight: bold !important;
    min-width: 90px !important;
  }
  .teamLeavesData {
    font-size: calc(12px + 0.004rem) !important;

    min-width: 90px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .teamLeavesHeading {
    font-weight: bold !important;
    font-size: calc(12px + 0.2rem) !important;
  }
  .teamLeavesData {
    font-size: calc(12px + 0rem) !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .teamLeavesHeading {
    font-weight: bold !important;
    font-size: calc(12px + 0.4rem) !important;
  }
  .teamLeavesData {
    font-size: calc(12px + 0.2rem) !important;
  }
}

@media screen and (min-width: 2000px) {
  .teamLeavesHeading {
    font-size: calc(12px + 1rem) !important;
    font-weight: bold !important;
  }
  .teamLeavesData {
    font-size: calc(12px + 0.8rem) !important;
  }
  .css-19l58oq-MuiButtonBase-root-MuiButton-root {
    width: 60%;
  }
}
