@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .accordionSection1 {
    gap: 60px !important;
    /* margin-left: 50px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .accordionSection1 {
    gap: 23px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .accordionSection1 {
    gap: 80px;
  }
}

@media only screen and (min-width: 2560px) {
  .accordionSection1 {
    gap: 240px;
  }
}

/* .summarySection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */
