// // @font-face {
// //     font-family: 'Outfit';
// //     src: local('Outfit'), url(./assets/fonts/outfit/Outfit-Regular.ttf) format('truetype');
// // }

// body {
//   margin: 0;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   font-family: "Outfit", sans-serif;
// }

// html,
// body,
// :-webkit-any-link {
//   text-decoration: none;
// }

// :-moz-any-link {
//   text-decoration: none;
// }

// :any-link {
//   text-decoration: none;
// }

// #root {
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
// }

// .form-control {
//   display: inline-flex;
//   flex-direction: column;
//   width: 100%;
//   text-align: center;

//   > label {
//     margin-bottom: 5px;
//     font-size: 12px;
//     text-align: left;
//     color: rgba(0, 0, 0, 0.54);
//   }

//   .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
//     border-color: #025e5e;
//   }
// }

// .socialMediaCard {
//   .field-wrapper-row {
//     margin-bottom: 15px;
//   }
// }

// .field-wrapper-row {
//   display: flex;
//   margin-bottom: 20px;

//   > div {
//     margin: 0 10px;
//     flex: 1;
//     text-align: left;
//   }

//   > .charity-doc-no {
//     min-width: 100px;
//     max-width: 100px;
//   }
// }

// .MuiInputAdornment-positionStart {
//   .MuiSvgIcon-root {
//     padding-right: 14px;
//     border-right: 1px solid #ccc;
//   }

//   img {
//     padding-right: 14px;
//     border-right: 1px solid #ccc;
//   }
// }

// .MuiInputAdornment-positionEnd {
//   .MuiSvgIcon-root {
//     color: red;
//   }
// }

// .flex-design {
//   display: flex;
// }

// .paddingTop {
//   padding-top: 14px;
//   text-align: center !important;
//   display: flex;
// }

// .paddingTopRagioGroup {
//   padding-top: 0px;
//   text-align: center !important;
//   display: flex;
// }

// .lablbtn {
//   display: block;
//   width: 130px;
//   padding-top: 13px;
// }

// .lablbtn > input {
//   /* HIDE RADIO */
//   visibility: hidden;
//   /* Makes input not-clickable */
//   position: absolute;
//   /* Remove input from document flow */
// }

// .lablbtn > input + div {
//   /* DIV STYLES */
//   cursor: pointer;
//   height: 26px;
//   border-radius: 6px;
//   padding-top: 7px;
//   border: 1px solid #025e5e;
// }

// .lablbtn > input:checked + div {
//   /* (RADIO CHECKED) DIV STYLES */
//   background-color: #025e5e;
//   color: #ffffff;
//   font-weight: bold;
// }

// .radio-group {
//   /* (RADIO CHECKED) DIV STYLES */
//   border: 1px solid #025e5e;
//   font-weight: 600;
//   color: #025e5e;
// }

// .labl {
//   display: block;
//   width: 100px;
//   padding-top: 13px;
// }

// .labl > input {
//   /* HIDE RADIO */
//   visibility: hidden;
//   /* Makes input not-clickable */
//   position: absolute;
//   /* Remove input from document flow */
// }

// .labl1 > input + div {
//   /* DIV STYLES */
//   cursor: pointer;
//   height: 30px;
//   border-radius: 6px;
//   padding-top: 10px;
//   border: 1px solid #025e5e;
// }

// .labl1 > input:checked + div {
//   /* (RADIO CHECKED) DIV STYLES */
//   background-color: #025e5e;
//   padding-top: 10px;
//   color: #ffffff;
//   font-weight: bold;
// }

// .radio-group1 {
//   /* (RADIO CHECKED) DIV STYLES */
//   border: 1px solid #025e5e;
//   font-weight: 600;
//   color: #025e5e;
// }

// .padding-right {
//   padding-right: 10px;
// }

// // body,
// // .MuiButton-root {
// //   font-family: "Outfit", sans-serif !important;
// //   background-color: #e3e7ef;
// // }

// /* end only demo styles */

// .checkbox-custom,
// .radio-custom {
//   opacity: 0;
//   position: absolute;
// }

// .checkbox-custom,
// .checkbox-custom-label,
// .radio-custom,
// .radio-custom-label {
//   display: inline-block;
//   vertical-align: middle;
//   margin: 5px;
//   cursor: pointer;
// }

// .checkbox-custom-label,
// .radio-custom-label {
//   position: relative;
// }

// .checkbox-custom + .checkbox-custom-label:before,
// .radio-custom + .radio-custom-label:before {
//   content: "";
//   background: #fff;
//   border: 2px solid #ddd;
//   display: inline-block;
//   vertical-align: middle;
//   width: 20px;
//   height: 20px;
//   padding: 2px;
//   margin-right: 10px;
//   text-align: center;
// }

// .checkbox-custom:checked + .checkbox-custom-label:before {
//   content: "\f00c";
//   font-family: "FontAwesome", sans-serif;
//   background: rebeccapurple;
//   color: #fff;
// }

// .radio-custom + .radio-custom-label:before {
//   border-radius: 50%;
// }

// .radio-custom:checked + .radio-custom-label:before {
//   content: "\f00c";
//   font-family: "FontAwesome", sans-serif;
//   color: #bbb;
// }

// .checkbox-custom:focus + .checkbox-custom-label,
// .radio-custom:focus + .radio-custom-label {
//   outline: 1px solid #ddd;
//   /* focus style */
// }

// .MuiGrid-item.verical-center {
//   display: flex;
//   justify-content: center;

//   label {
//     margin: auto;
//   }
// }

// .action-button {
//   margin-top: 30px;
//   margin-bottom: 30px;
//   text-align: center;
// }

// .custom-breadcrumbs {
//   padding-left: 90px;
// }

// .custom-breadcrumbs.MuiTypography-body1 {
//   font-weight: 700;
// }

// .content-breadcrumbs.MuiTypography-body1 {
//   padding: 0px;
//   font-size: 12px;
//   font-weight: 500;
//   margin-bottom: 15px;

//   p {
//     font-size: 12px;
//   }
// }

// .preferences-card.MuiTypography-body1 {
//   padding-left: 24px;
//   font-weight: 700;
// }

// .flex {
//   display: flex;
// }

// .main-container-wrapper {
//   padding-left: 50px;
//   padding-right: 50px;
//   // align-self: center;

//   .MuiToolbar-regular {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }

// @media (min-width: 600px) {
//   .MuiToolbar-regular {
//     min-height: 48px !important;
//   }
// }

// .labl-radio {
//   display: block;
//   width: auto;
//   flex-wrap: wrap;
//   flex-direction: column;
//   padding-right: 16px;
//   line-height: 35px;
//   margin: 8px;
// }

// .labl-radio > input {
//   /* HIDE RADIO */
//   visibility: hidden;
//   /* Makes input not-clickable */
//   position: absolute;
//   /* Remove input from document flow */
// }

// .labl-radio > input + div {
//   /* DIV STYLES */
//   cursor: pointer;
//   border-radius: 12px;
//   flex-direction: row;
//   flex-wrap: wrap;
// }

// .labl-radio > input:checked + div {
//   /* (RADIO CHECKED) DIV STYLES */
//   background-color: #025e5e;
//   border: 1px solid #025e5e;
//   color: white;
// }

// .radio-button-div {
//   border-color: #025e5e;
//   border-style: solid;
//   border-width: 1px;
//   text-align: center;
//   border-radius: 12px;
//   color: #025e5e;
//   width: fit-content;
//   padding-left: auto;
//   padding-left: 40px;
//   padding-right: 40px;
//   justify-content: space-between;
// }

// .flex-buttons {
//   display: flex;
//   flex-wrap: wrap;
//   width: calc(100% + 0px);
//   justify-content: flex-start;
//   margin-left: -10px;
// }

// .radio-check-button .MuiFormGroup-root {
//   display: flex;
//   flex-wrap: wrap;
//   width: calc(100% + 0px);
//   justify-content: flex-start;
//   margin: 6px;
// }

// .radio-check-button .MuiFormGroup-root label {
//   min-width: 20%;
//   margin: 10px;
// }

// .fileuplod-button {
//   width: calc(100% + 16px);
//   flex-wrap: wrap;
//   margin-top: -36px;
// }

// .upload-file-align .form-control {
//   width: 50%;
//   margin-top: -47px;
// }

// .amount-raised-div {
//   margin-top: -16px !important;
// }

// .error-msg {
//   color: red !important;
//   font-size: 13px;
// }

// .iconlbl {
//   .MuiSvgIcon-root {
//     padding-right: 4px;
//   }
// }

// .regional-select {
//   padding-left: 46px;
// }

// .custom-select::before {
//   // content: url(./assets/img/icons/doc.png);
//   padding-left: 14px;
//   padding-right: 10px;
//   border-right: 1px solid #ccc;
//   height: 23px;
// }

// .person-select::before {
//   // content: url(./assets/img/icons/userProfile.png);
//   padding-left: 14px;
//   padding-right: 10px;
//   border-right: 1px solid #ccc;
//   height: 23px;
// }

// .user-icon::before {
//   // content: url(./assets/img/icons/user-icon.png);
//   padding-left: 14px;
//   padding-right: 14px;
//   border-right: 1px solid #ccc;
//   height: 18px;
// }

// .user-icon-avatar::before {
//   // content: url(./assets/svg/user-avatar-new.svg);
//   padding-left: 14px;
//   padding-right: 14px;
//   border-right: 1px solid #ccc;
//   height: 20px;
// }

// .custom-date-input input[type="date"]::-webkit-inner-spin-button,
// .custom-date-input input[type="date"]::-webkit-calendar-picker-indicator {
//   // display: none;
//   // -webkit-appearance: none;
//   .content-heading {
//     h4 {
//       font-weight: 700;
//       text-align: left;
//       font-size: 26px;
//       margin-bottom: 25px;
//     }

//     p {
//       margin: 0;
//       font-size: 16px;
//       margin-bottom: 5px;
//     }
//   }
// }

// .select-offline-donations .MuiSelect-select {
//   background-color: white;
//   border-radius: 10px;
// }

// .error-text.MuiTypography-body1 {
//   text-align: left;
//   padding-left: 24px;
//   margin-bottom: 5px;
//   font-size: 13px;
// }

// .content-heading4 {
//   h4 {
//     font-weight: 600;
//     text-align: left;
//     font-size: 26px;
//     margin-bottom: 25px;
//   }

//   p {
//     margin: 0;
//     font-size: 16px;
//     margin-bottom: 5px;
//   }
// }

// .radiogroup-padding {
//   padding-top: 12px;
// }

// .charity-heading-style {
//   // .MuiTypography-caption {
//   text-align: left;
//   padding: 0px 20px;
// }

// .charity-heading-style .MuiTypography-colorPrimary {
//   font-weight: bold;
//   margin-top: 20px;
//   margin-bottom: 4px;
//   font-size: 20px;
// }

// .required-holder-mydetails {
//   color: #f17b7b;
//   font-size: 13px;
//   margin-bottom: 18px;
//   position: relative;
// }

.main-content-wrapper {
  padding-left: 100px;
  height: auto;
}
.with-side-menu {
  height: 100%;
  width: 100%;
  background-color: white !important;
}

@media only screen and (min-width: 2000px) {
}

//   // transform: translate(0px, 88px);
//   // transform: translate(0px, 80px);

//   // transition: transform 0.2s ease-in;
//   // // padding-bottom: 150px;
//   // padding-bottom: 80px;
// }

// .notify-msg + .main-content-wrapper {
//   // transform: translate(0px, 150px);
//   // transform: translate(0px, 132px);
//   padding-bottom: 150px;
// }

// .dashboard-layout {
//   padding-top: 10px;
// }

// .fixed-header {
//   // height: 101px;
//   // height: 80px;
//   // position: fixed;
//   // top: 0;
//   // left: 0;
//   // width: 100%;
//   // z-index: 999;
//   // background: #ffffff;
// }

// .fixed-header.notify-msg {
//   background: #e3e7ef;
//   // padding-top: 20px;
// }

// .fixed-header + .footer-header {
//   // padding-top: 98px;
//   // padding-top: 155px;
// }

// .fixed-header.notify-msg + .footer-header {
//   // padding-top: 155px;
// }

// .main-content-wrapper {
// }

// .userprofile-card {
//   background-color: #edf2f4;
//   padding: 35px;
//   border-radius: 8px;
// }

// .report-action-button {
//   margin-top: 30px;
//   margin-bottom: 30px;
//   width: 70%;
//   display: "flex";
//   justify-content: space-around;
// }

// .others-input-wrapper {
//   //   .MuiInputLabel-outlined {
//   //     transform: translate(14px, 11px) scale(1);
//   //   }

//   // > .MuiInputBase-root {
//   //   > .MuiOutlinedInput-input {
//   //     padding: 10px 14px;
//   //   }
//   // }
// }

// .max-body-content {
//   //need to do changes
//   padding: 10px 96px;
//   position: relative;
//   max-width: 1200px;
//   margin: 0 auto 40px;
// }

// .popup-content {
//   width: 550px !important;
//   height: 360px;
//   border-radius: 20px !important;
// }

// .popup-root .popup-content {
//   border-radius: "10px" !important;
// }

// .hideIt {
//   display: none;
// }

// .slider-img {
//   height: 286px;
//   width: 100%;
//   border-radius: 20px;
// }

// ::placeholder {
//   font-style: italic;
// }

// // 31/12/2022
// .MuiInputBase-input {
//   min-height: 26px;
// }

// .append-currency {
//   padding-right: 0;
// }

// .side-pic-wrap {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   overflow: hidden;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   img {
//     width: 50px;
//     max-width: 100%;
//     height: 50px;
//     background-size: cover;
//     border-radius: 50% 50% 50% 50%;
//   }
// }

// .field-wrapper-row .margin-right-10 {
//   margin-right: 20px;
// }

// .field-wrapper-row .margin-left-10 {
//   margin-left: 20px;
// }

// .camp-category {
//   padding-left: 5px;
//   color: #025e5e;

//   .MuiTypography-body1 {
//     font-size: 0.8rem;
//   }
// }

// .react-multiple-carousel__arrow .react-multiple-carousel__arrow--left {
//   // background-image: url("data:image/svg+xml,%3Csvg xmlns='./assets/svg/arrow-right.svg' viewBox='0 0 600 200'%3E%3Cpath d='M10 10h123v123H10z'/%3E%3C/svg%3E") !important;
//   background-repeat: no-repeat !important;
// }

// .react-multiple-carousel__arrow {
//   min-width: 30px !important;
//   min-height: 30px !important;
// }

// .react-multiple-carousel__arrow--right {
//   top: 110px;
//   background-color: transparent !important;
//   // background-image: url('./assets/svg/arrow-right-wt.svg') !important;
//   background-size: cover !important;
//   background-repeat: no-repeat !important;
//   cursor: pointer !important;
// }

// .react-multiple-carousel__arrow--right:hover {
//   background-color: transparent !important;
//   // background-image: url('./assets/svg/arrow-right-wt.svg') !important;
//   background-size: cover !important;
//   background-repeat: no-repeat !important;
// }

// .react-multiple-carousel__arrow--right::before {
//   content: "" !important;
//   // background-image: url('./assets/svg/arrow-right-wt.svg') !important;
// }

// .react-multiple-carousel__arrow--left {
//   top: 110px;
//   background-color: transparent !important;
//   // background-image: url('./assets/svg/arrow-left-wt.svg') !important;
//   background-size: cover !important;
//   background-repeat: no-repeat !important;
//   cursor: pointer !important;
// }

// .react-multiple-carousel__arrow--left:hover {
//   background-color: transparent !important;
//   // background-image: url('./assets/svg/arrow-left-wt.svg') !important;
//   background-size: cover !important;
//   background-repeat: no-repeat !important;
// }

// .react-multiple-carousel__arrow--left::before {
//   content: "" !important;
// }

// .campaignWrapper {
//   width: 100%;
//   text-decoration: none;
// }

// .campaignWrapperFeatured {
//   margin: 0px 6px;
// }

// .campaignWrapperFeatured .MuiPaper-root {
//   background-color: rgb(223, 234, 238) !important;
// }

// .campaignWrapperFeatured .MuiPaper-root:hover {
//   background-color: #ffffff !important;
// }

// .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
//   opacity: 0;
// }

// .MuiTooltip-tooltip {
//   background-color: #def1f1 !important;
//   color: #025e5e !important;
//   font-weight: 600 !important;
// }

// #other-category {
//   .MuiTooltip-tooltip {
//     background-color: #ffffff !important;
//   }
// }

// .cardDefaultStyles {
//   padding: 20px;
// }

// #session-expired-modal {
//   .MuiDialog-paper {
//     background: linear-gradient(315deg, #f33e36 80%, #ff9494);
//     color: #fff;
//     font-weight: 600;
//     padding: 5px 15px;
//     box-sizing: border-box;

//     .MuiTypography-colorTextSecondary,
//     .MuiButton-textPrimary {
//       color: inherit;
//       font-weight: 600;
//     }

//     .session-expired-heading {
//       display: flex;
//       align-items: center;
//       font-weight: 600;
//     }
//   }
// }

// @media only screen and (max-width: 800px) {
//   // .fixed-header {
//   //     height: auto;
//   // }

//   .main-content-wrapper {
//     transform: translate(0px, 90px);
//   }
// }

// @media only screen and (max-width: 600px) {
//   .notify-msg + .main-content-wrapper {
//     padding-bottom: 170px;
//   }

//   .main-content-wrapper {
//     transform: translate(0px, 165px);
//   }
// }

// .profileCard {
//   width: 35%;
//   height: auto;
//   background: #ffffff;
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
//   margin-left: 2vh;
// }

// .spinner1Card {
//   position: absolute;
//   width: auto;
//   height: auto;
//   left: 35vw;
//   top: 0px;
//   background: #ffffff;
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
// }

// .spinner2Card {
//   position: absolute;
//   width: auto;
//   height: auto;
//   right: 2vw;
//   top: 0px;
//   background: #ffffff;
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
// }

// .assetsCard {
//   position: absolute;
//   width: 62vw;
//   height: auto;
//   right: 2vw;
//   top: 51vh;
//   background: #ffffff;
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
// }

// .assets > p {
//   margin-top: 10px;
// }

// .summaryCard {
//   position: absolute;
//   width: 62vw;
//   height: auto;
//   right: 2vw;
//   /* bottom: 22.3vh; */
//   top: 89vh;
//   background: #ffffff;
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   border-radius: 10px;
// }

// .summaryTitle {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 17px;
//   line-height: 28px;
//   color: #162952;
//   margin: 0;
// }

// .leavesContent {
//   background-color: white;
//   width: auto;
//   height: auto;
//   text-align: center;
//   margin: 1% 4%;
// }

// // .line_bar{
// //     height: 20px;
// //     border-right: 1px solid #162952;
// //     position: absolute;
// //     left: 24%;
// //     margin-left: -3px;
// //     top: 6vh;
// // }
// // .firstRow{

// // }
// hr.solid {
//   border-top: 0.5px #162952;
//   margin-left: 4vh;
//   margin-right: 4vh;
// }

// .profileAvatarImage {
//   left: 10vw;
//   margin-left: 7vw;
//   /* top: 13vh; */
//   margin-top: 7vh;
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-origin: content-box;
//   width: 200px;
//   /* height: 200px; */
//   border-radius: 70%;
// }

// .cardBodyCss {
//   margin-left: -1vw;
//   margin-top: 10vh;
// }

// .circle {
//   // background-color: white;
//   // border-radius: 50%;
//   // display: inline-block;
//   // height: 210px;
//   // width: 210px;
//   // margin-left: 13.8vw;
//   // margin-top: 4.79vh;
//   // position: absolute;
//   // border: solid;
//   // border-color: black;
//   border-radius: 50%;
//   width: 160px;
//   height: 160px;
//   padding: 10px;
//   background: #fff;
//   border: 2px solid #000;
//   color: #000;
//   /* margin-left: 2vw; */
//   /* z-index: -1; */
//   /* background-position-x: inherit; */
//   margin-left: 17vh;
// }

// .dataCss {
//   margin-top: -15px;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 23px;
//   color: #162952;
// }

// .leftDataTypographyCss {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 18px;
//   color: #9d9d9d;
// }

// .rightDataDiv {
//   position: relative;
//   margin-left: 22vw;
//   margin-top: -27.75vw;
// }

// .profileCardTitle {
//   text-align: -webkit-center;
// }

// .profileSubTitle {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 19px;
//   line-height: 22px;
//   text-align: center;
//   color: #162952;
//   padding-bottom: 20px;
// }

// .cardDataPadding {
//   padding-top: 30px;
// }

// .progress1 {
//   width: 145px;
//   height: 145px;
//   transform: rotate(-90deg);
//   position: absolute;
//   margin-left: 5vh;
//   margin-top: 13vh;
//   color: #4bc2a6;
// }

// .progress1Title {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 22px;
//   line-height: 28px;
//   text-align: center;
//   color: #162952;
//   margin-top: 4vh;
//   position: absolute;
//   margin-left: 3vw;
// }

// .progress1Data {
//   position: absolute;
//   margin-top: -29vh;
//   z-index: 210;
//   margin-left: 16vw;
//   text-align: -webkit-right;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
//   text-transform: uppercase;
//   color: #9d9d9d;
// }

// .leaveButtonCss {
//   background: linear-gradient(
//     15.27deg,
//     #ededed 11.88%,
//     rgba(255, 255, 255, 0) 90.82%
//   );
//   filter: drop-shadow(0px 2px 5px rgba(52, 100, 193, 0.26));
//   border-radius: 10px;
//   width: 120px;
//   height: 35px;
// }

// .leaveButtonValue {
//   // font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 15px;
//   line-height: 23px;
//   color: #3464c1;
//   text-transform: none;
//   margin-top: 11%;
// }

// .row1 {
//   max-width: 250px;
//   min-width: 200px;
//   height: 150px;
//   box-sizing: border-box;
//   /* background-color: red; */
//   margin: 2rem;
//   flex: 1;
// }

// .row2 {
//   margin-left: 18vw;
//   margin-top: -11.5rem;
//   max-width: 250px;
//   min-width: 200px;
//   height: 150px;
//   box-sizing: border-box;
// }

// .assetsDataDiv {
//   margin-top: -2vh;
//   margin-left: 2vw;
// }

// .assetsDataCss {
//   font-size: 16px;
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   line-height: 23px;

//   color: #162952;
// }

// .summaryDataCss {
//   margin-left: 4vh;
//   margin-right: 4vh;
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 23px;
//   margin-top: -2vh;
//   color: #162952;
// }

// .profileCard-outer {
//   width: 35%;
//   // margin-left: 5vw;
// }

// .profile-Card {
//   background-color: white;
//   border-radius: 2%;
//   text-align: center;
//   align-items: center;
//   padding: 4% 4% 1% 7%;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// }

// .withoutProfileCard {
//   width: 68%;
//   height: auto;
//   border-radius: 5%;
//   padding-left: 2%;
// }

// .myDiv {
//   display: flex;
//   justify-content: space-between;
//   margin: 0px;
//   padding: 1% 1%;
//   background-color: #e3e7ef;
// }

// .profilePicture {
//   height: 140px;
//   width: 140px;
//   border-radius: 50%;
// }

// .profilePictureDiv {
//   height: 150px;
//   width: 150px;
//   border-radius: 50%;
//   margin-left: auto;
//   margin-right: auto;
// }

// .avatar {
//   margin-left: auto;
//   margin-right: auto;
// }

// .avatar img {
//   border: solid 6px transparent;
//   border-radius: 50%;
//   // height: 150px;
//   width: 160px;
//   height: 160px;
//   left: 382px;
//   top: 129px;
// }

// @media only screen and (max-width: 750px) {
//   .avatar img {
//     width: auto;
//   }
// }

// .assets {
//   width: 100%;
//   background-color: white;
//   height: auto;
//   margin-top: 3.4vh;
//   border-radius: 2%;
//   padding: 2% 3% 2% 3%;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// }

// .grid {
//   display: grid;
//   grid-auto-flow: column;
// }

// .grid > .leaves {
//   width: 97.5%;
//   height: auto;
//   background-color: white;
//   border-radius: 3%;
//   display: flex;
//   padding: 3%;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// }

// @media only screen and (max-width: 750px) {
//   .grid > .leaves {
//     flex-wrap: wrap;
//     flex-direction: column;
//   }
// }

// .textRow {
//   height: 49px;
//   margin-top: 15px;
//   display: flex;
//   margin-bottom: 18px;
// }

// .leftData {
//   height: auto;
//   width: 50%;
// }

// .rightData {
//   width: 50%;
//   height: auto;
// }

// .label {
//   color: #9d9d9d;
//   font-family: "sans";
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 17.58px;
//   text-align: left;
//   margin: 0;
//   word-wrap: break-word;
// }

// .value {
//   color: #162952;
//   font-family: sans;
//   font-weight: 400;
//   font-size: 13px;
//   line-height: 17.5px;
//   text-align: left;
//   margin: 0;
//   word-wrap: break-word;
// }

// // employee list

// .empName {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 300;
//   font-size: 24px;
//   line-height: 39px;
//   text-align: center;
//   color: #f15830;
//   margin: 0% 0 0 0;
// }

// .empDesignation {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 22px;
//   text-align: center;
//   color: #162952;
//   margin: 0;
// }

// .textarea {
//   margin-top: 11%;
// }

// .summary {
//   width: 96.85%;
//   border-radius: 3%;
// }

// .summaryDiv {
//   display: flex;
// }

// .summaryContent {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 10px;
//   line-height: 15px;
//   color: #162952;
// }

// .CircularProgressbar.circularProgressBar {
//   width: auto;
//   margin-top: 11%;
// }

// @media only screen and (max-width: 750px) {
//   .CircularProgressbar.circularProgressBar {
//     margin-bottom: 15px;
//   }
// }

// .leavesData {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
//   text-transform: uppercase;
//   color: #9d9d9d;
//   text-align: right;
//   margin: 3% 0;
// }

// .boldText {
//   color: #162952;
//   font-size: 15px;
// }

// .leaveButton {
//   border-radius: 10px;
//   width: 55%;
//   height: 6vh;

//   background: linear-gradient(
//     15.27deg,
//     #ededed 11.88%,
//     rgba(255, 255, 255, 0) 90.82%
//   );
//   filter: drop-shadow(0px 6px 10px rgba(52, 100, 193, 0.26));
//   border-radius: 10px;
// }

// .leaveButtonDiv {
//   margin-top: 25%;
//   text-align: right;
//   width: 13vw;
//   padding-right: 5%;
// }

// .linkedInButton {
//   // background: #ffffff;
//   border: 1px solid #dbdbdb;
//   border-radius: 5px;
//   color: #0077b5;
//   // // margin: 10px;
//   // background-color: red;
//   width: 50%;
//   height: 10%;
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 6.3%;
//   padding: 3% 0;
//   word-wrap: break-word;
//   font-size: 14px;
// }

// .linkedIcon {
//   height: 38%;
//   width: 8%;
//   margin-top: 1%;
// }

// .hidecontent {
//   display: none;
// }

// #myaccordion label {
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   display: block;
//   padding: 8px 22px;
//   margin: 20px 0px 1px 0px;
//   cursor: pointer;
//   background: #ffffff;
//   color: #162952;
//   transition: ease 0.5s;
//   height: auto;
//   border-radius: 10px;
// }

// // #myaccordion label:hover {
// //     background: white;
// // }
// .content {
//   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
//   background: #ffede9;
//   padding: 10px 25px;
//   border: 1px solid #d4d4d4;
//   margin: -1 0 0 0;
//   border-radius: 0px 0px 10px 10px;
// }

// #myaccordion input:checked + label + .content {
//   display: block;
//   webkit-animation: fadeIn 0.5s ease-out;
//   -moz-animation: fadeIn 0.5s ease-out;
//   -o-animation: fadeIn 0.5s ease-out;
//   animation: fadeIn 0.5s ease-out;
// }

// @-webkit-keyframes fadeIn {
//   0% {
//     display: none;
//     opacity: 0;
//   }

//   1% {
//     display: block;
//     opacity: 0;
//   }

//   100% {
//     display: block;
//     opacity: 1;
//   }
// }

.wrapper {
  width: 90vw;
  //   margin-left: 0vw;
  //   margin-right: 1vw;
  //   padding-bottom: 5vh;
  //   margin-top: 3vh;
  //   background-color: #e3e7ef;
}

// .prof-image {
//   border-radius: 50%;
//   height: 63px;
//   margin-top: 0%;
//   margin-left: 2%;
//   z-index: 100;
// }

// .allEmpLeftData {
//   height: auto;
//   width: 50%;
//   margin-left: 2%;
//   margin-top: 1%;
// }

// .allEmpNameLabel {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 23px;
//   color: #162952;
//   text-align: left;
//   margin: 0;
//   word-wrap: break-word;
// }

// .allEmpLabelValue {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 13px;
//   line-height: 18px;
//   color: #162952;
//   text-align: left;
//   margin: 0;
//   word-wrap: break-word;
// }

// .allEmpHeader {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 19px;
//   /* identical to box height */
//   color: #727272;
// }

// .headerDiv {
//   margin-left: 32%;
//   width: 100vw;
// }

// .header1 {
//   margin-left: 18.5%;
// }

// .header2 {
//   margin-left: 9%;
// }

// .header3 {
//   margin-left: 10%;
// }

// .header4 {
//   margin-left: 11%;
// }

// .header5 {
//   margin-left: 9.5%;
// }

// .header6 {
//   margin-left: 7%;
// }

// .headerValue {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 20px;
//   color: #162952;
//   word-break: break-word;
// }

// .valueDiv {
//   margin-left: 8%;
//   margin-bottom: 1%;
//   /* margin: 0; */
//   padding: 0;
//   width: 81%;
//   /* width: 100%; */
//   word-break: break-word;
// }

// .contentDataHeader {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 13px;
//   line-height: 18px;
//   color: #ab8277;
// }

// .contentDataValue {
//   margin-top: -2vh;
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 23px;
//   color: #162952;
// }

// .contentDataDiv {
//   margin-left: 5%;
// }

// .contentDataDiv1 {
//   margin-left: 1%;
// }

// .headerValue2 {
//   margin-left: 5%;
// }

// .headerValue3 {
//   margin-left: 3%;
//   width: 24%;
// }

// .headerValue4 {
//   margin-left: 3%;
// }

// .headerValue5 {
//   margin-left: 7.5%;
//   width: 10%;
// }

// .headerValue6 {
//   margin-left: 5%;
// }

// .moreIconDiv {
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: -10px;
// }

// // .MuiSvgIcon-root
// // {
// //     color: #F15830;
// // }

// // .css-6hp17o-MuiList-root-MuiMenu-list {
// //   list-style: none;
// //   margin: 0;
// //   padding: 0;
// //   position: relative;
// //   padding-top: 8px;
// //   padding-bottom: 8px;
// //   outline: 0;
// //   font-family: "Roboto";
// //   font-style: normal;
// //   font-weight: 400;
// //   font-size: 17px;
// //   line-height: 226.69%;
// //   color: #f15830;
// // }

// .has-search .form-control {
//   padding-left: 2.375rem;
// }

// .has-search .form-control-feedback {
//   position: absolute;
//   z-index: 2;
//   display: block;
//   width: 2.375rem;
//   height: 2.375rem;
//   line-height: 2.375rem;
//   text-align: center;
//   pointer-events: none;
//   color: #aaa;
// }

// .disabledTab {
//   pointer-events: none;
//   opacity: 0.7;
// }

// .inactiveBadge {
//   z-index: 500;
//   background: linear-gradient(268.1deg, #ffffff 7.66%, #bebebe 89.89%);
//   /* box-shadow: 5px 5px 10px rgb(0 0 0 / 10%); */
//   border-radius: 0px 10px 0px 10px;
//   /* right: 2px; */
//   /* margin-left: 257px; */
//   /* margin-left: 14%; */
//   position: absolute;
//   /* margin-left: 40%; */
//   right: 1.9vw;

//   /* top: -21vh; */
//   /* bottom: -33vw; */
//   margin-top: -0.68%;
//   height: 3%;
// }

// .inactiveShow {
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 9px;
//   line-height: 15px;
//   letter-spacing: 0.185em;
//   color: #b16b59;
//   margin: 3%;
//   margin-left: 0%;
//   padding: 0px 15px 0px 25px;
// }

// .inactiveCover {
//   display: none;
// }

// .ui.search {
//   margin-top: 50px;
//   text-align: center;
// }

// i {
//   cursor: pointer;
// }

// .formCSS {
//   background-color: #4654e1;
//   width: 300px;
//   height: 44px;
//   border-radius: 5px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// }

// .searchInput {
//   all: unset;
//   font: 16px system-ui;
//   color: #fff;
//   height: 100%;
//   width: 100%;
//   padding: 6px 10px;
// }

// .placeholder {
//   color: #fff;
//   opacity: 0.7;
// }

// .searchsvg {
//   color: #fff;
//   fill: currentColor;
//   width: 24px;
//   height: 24px;
//   padding: 10px;
// }

// .searchbutton {
//   all: unset;
//   cursor: pointer;
//   width: 44px;
//   height: 44px;
// }

// .long-menu {
//   position: fixed;
//   z-index: 1300;
//   right: 98px;
//   bottom: 0;
//   top: 0px;
//   left: -68px;
//   width: 120px;
// }

// // .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
// //   position: fixed;
// //   z-index: 1300;
// //   right: 0;
// //   bottom: 0;
// //   top: 0;
// //   left: -60px;
// //   width: 150px;
// // }

// .searchDiv {
//   width: 15%;
//   margin-bottom: 5vh;
//   margin-left: 83.5%;
// }

// .searchInput {
//   height: 4vh;
//   position: relative;
//   border: 0;
//   background: #ffffff;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
//   border-radius: 10px;
//   color: #000;
// }

// .searchIcon {
//   position: absolute;
//   margin-right: 2vw;
//   margin-left: 13.2vw;
//   margin-top: -4.2vh;
// }

// .allEmployeesHeading {
//   font-family: "sans";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 22px;
//   line-height: 30px;
//   text-transform: uppercase;

//   color: #162952;
//   position: absolute;
//   top: 5vh;
// }

// // .content-menu {
// //     max-width: 70px;
// // }

// // .content-menu.collapse.sub-collapse .main-menu {
// //     width: 70px;
// //     background: white;
// // }

// // .content-menu.sub-collapse .main-menu {
// //     width: 225px;
// //     background: white;
// // }

// // .content-menu.collapse.sub-collapse {
// //     width: 70px;
// // }

// // .content-menu {
// //     max-width: 70px;
// // }

// // .content-menu.collapse.sub-collapse .main-menu {
// //     width: 70px;
// //     background: white;
// // }

// .content-menu.collapse.sub-collapse {
//   width: 68px;
// }

// .content-menu.collapse.sub-collapse .main-menu {
//   width: 90px;
// }
// MuiBox-root css-zxdg2z .main-menu .menu-list-item {
//   min-width: 20px;
//   display: flex;
//   width: 100%;
// }

// .content-menu {
//   background-image: linear-gradient(to right, #ffffff 225px, #f0f7f7 225px);
//   // width: 28.5%;
//   width: 17.5%;
// }

// .menu-icon {
//   width: 24px;
//   height: 40px;
//   margin-right: 14px;
//   flex-shrink: 0;
//   margin-top: 5px;
//   padding: 0px 0px 40px 0px;
// }

// .menu-label {
//   text-align: left;
//   line-height: 18px;
//   white-space: nowrap;
//   font-size: 0.82rem;
//   transition: width 2s;
//   text-transform: capitalize;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   padding: 2px;
// }

// .addEmployeeButton {
//   position: absolute;
//   //   width: 165px;
//   height: 39px;

//   // background: #FFFFFF;
//   // border: 1px solid #F15830;

//   // border-radius: 10px;
//   right: 18%;
// }

// .addEmployeeButtonCss {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 15px;
//   line-height: 18px;
//   /* identical to box height */
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 18px;
//   color: #f15830;
// }

// .tooltip-text {
//   visibility: hidden;
//   position: absolute;
//   z-index: 2;
//   width: 100px;
//   color: #162952;
//   font-size: 12px;
//   background-color: #192733;
//   border-radius: 10px;
//   padding: 10px 15px 10px 15px;
//   background: #ffede9;
//   // box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
// }

// .tooltip-text::before {
//   content: "";
//   position: absolute;
//   transform: rotate(45deg);
//   background-color: #ffede9;
//   padding: 5px;
//   z-index: 1;
// }

// .hover-text:hover .tooltip-text {
//   visibility: visible;
// }

// #top {
//   top: -44px;
//   left: 0%;
//   width: max-content;
// }

// #top::before {
//   top: 80%;
//   left: 10%;
// }

// .hover-text {
//   position: absolute;
// }

// .tooltip-textBlue {
//   visibility: hidden;
//   position: absolute;
//   z-index: 2;
//   width: 100px;
//   color: white;
//   font-size: 12px;
//   background-color: #192733;
//   border-radius: 10px;
//   padding: 10px 15px 10px 15px;
//   background: #192733;
//   // box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
// }

// .tooltip-textBlue::before {
//   content: "";
//   position: absolute;
//   transform: rotate(45deg);
//   background-color: #192733;
//   padding: 5px;
//   z-index: 1;
// }

// // .hover-text:hover .tooltip-textBlue {
// //   visibility: visible;
// // }

// //   #top2 {
// //     top: -44px;
// //     left: 0%;
// //     width: 50vw;
// //     // height: auto;
// //     word-break: break-word;
// //   }

// //   #top2::before {
// //     top: 80%;
// //     left: 10%;
// //   }

// #bottom {
//   top: 25px;
//   left: -1%;
//   width: 45vw;
// }

// #bottom::before {
//   top: -5%;
//   left: 5%;
// }

// // css for employee list ends here

// // Css for detailed forms

// // .button {
// //     margin-left: 200px;
// // }
// .iconDiv {
//   width: 25%;
//   height: auto;
//   background-color: #f8e7e3;
// }
// .formDiv {
//   width: 75%;
//   height: 75vh;
//   background-color: whitesmoke;
// }
// .twoDiv {
//   display: flex;
// }
// .modal-content {
//   width: 185%;
//   margin: 0 0% 0 -37%;
//   height: auto;
//   background-color: red;
// }
// .modal-header {
//   // padding: 0;
//   // border-bottom: 0;
//   display: none;
// }

// .closeButton {
//   margin-left: 95%;
//   height: 6vh;
//   background-color: #9d9d9d;
// }
// .modal-body {
//   padding: 0;
// }
// .imagesDiv {
//   // background-color: green;
//   height: 20%;
//   text-align: center;
//   margin-bottom: 16%;
// }
// .iconName {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 25px;
//   text-align: center;
//   text-transform: uppercase;
//   color: #cda59c;
//   cursor: pointer;
// }
// .imgStyle {
//   cursor: pointer;
// }
// .MuiBox-root.css-1n3h4wo {
//   height: 71vh;
//   width: 100%;
// }
// .imagePersonal {
//   // background-color: blue;
//   height: 10vh;
// }

// // .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableY.css-1cgx7kx-MuiTabs-scroller {
// //   background-color: #f8e7e3;
// // }
// .MuiTab-iconWrapper {
//   width: 30%;
// }
// // .css-aym9vq-MuiButtonBase-root-MuiTab-root {
// //   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
// //   font-style: normal;
// //   font-weight: 600;
// //   font-size: 17px;
// //   line-height: 20px;
// //   text-align: center;
// //   text-transform: uppercase;
// //   color: #333333;
// // }
// // .css-aym9vq-MuiButtonBase-root-MuiTab-root > .MuiTab-iconWrapper {
// //   margin-bottom: 0;
// // }
// .MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary.Mui-selected.css-aym9vq-MuiButtonBase-root-MuiTab-root {
//   color: #333333;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 20px;
//   outline: none;
// }
// .muipersonal {
//   background-color: black;
// }

// .nextNBack {
//   background-color: #efd8d3;
//   width: 99%;
//   height: 8vh;
//   margin-top: 19%;
// }
// .backButton {
//   cursor: pointer;
//   margin: 8% -23% 0 25%;
// }
// .nextButton {
//   cursor: pointer;
//   margin: 8% 25% 0 25%;
// }

// .financeDiv {
//   height: 50%;
//   display: flex;
//   overflow-y: auto;
//   overflow-x: auto;
//   word-wrap: break-word;
// }

// .MuiTabs-root.MuiTabs-vertical.css-1g4ac1o-MuiTabs-root {
//   overflow: unset;
// }
// .formHeading {
//   margin-top: 1%;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 21px;
//   color: #f15830;
//   display: flex;
//   align-items: center;
// }
// .line {
//   height: 0.1rem;
//   width: 43%;
//   background-color: #f2bfb3;
// }

// form > label > p {
//   margin-top: 16px;
//   margin-bottom: 0;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   line-height: 18px;
// }
// form > label > input {
//   border-radius: 5px;
//   border: 1px solid #acacac;
// }
// .required {
//   color: #f15830;
// }
// .section {
//   width: 95%;
// }

// .radio {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 15px;
//   line-height: 18px;
// }
// input[type="radio"] {
//   // /* remove standard background appearance */
//   // -webkit-appearance: none;
//   // -moz-appearance: none;
//   // appearance: none;
//   // /* create custom radiobutton appearance */
//   // display: inline-block;
//   // width: 14px;
//   // height: 14px;
//   // background-clip: content-box;
//   // border: 2px solid #bbbbbb;
//   // background-color: #e7e6e7;
//   // border-radius: 50%;
// }
// input[type="radio"]:checked {
//   background-color: #f15830;
// }

// .radioButtons {
//   align-items: center;
// }
// .additionalBenifitsDiv {
//   margin-top: 8%;
// }

// .personalDiv {
//   height: 65vh;
//   overflow: auto;
// }
// .generalDiv {
//   height: 75%;
//   margin-top: 2%;
// }
// .personalLine {
//   height: 0.1rem;
//   width: 90%;
//   background-color: #f2bfb3;
// }
// .personalFormInput {
//   // width: 75%;
// }
// .addressInput {
//   width: 159%;
//   height: 11vh;
// }
// .pFirstRow {
//   // background-color: red;
//   margin-bottom: 2%;
// }
// .pFirstRow > label > p {
//   margin-top: 0;
//   margin-bottom: 0;
// }
// .firstRowLabels {
//   width: 28%;
//   margin-right: 1%;
// }
// .radioButtons {
//   margin-right: 0;
// }
// .bloodGroup {
//   width: 12%;
//   margin-left: -2%;
// }
// .secondRowLabel {
//   width: 70%;
//   margin-right: 3%;
// }
// .passportExpiryDate {
//   margin-right: 0;
// }
// form > div > label > input {
//   background: #ffffff;
//   border: 1px solid #acacac;
//   border-radius: 5px;
//   width: 87%;
// }

// form > div > label > p {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   line-height: 18px;
//   color: #333333;
// }
// .radionText {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 16px;
//   color: #333333;
// }
// .checkBoxAddress {
//   // margin-left: 49%;
// }
// .checkBoxText {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 14px;
//   color: #868686;
//   margin-left: -39%;
// }

// //Emergency Details
// .modal-header .emergencyDiv {
//   height: 30%;
//   margin-top: 2%;
// }
// .emergencyFormHeading {
//   margin-top: 18%;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 21px;
//   color: #f15830;
//   display: flex;
//   align-items: center;
// }
// .emergencyLine {
//   height: 0.1rem;
//   width: 75%;
//   background-color: #f2bfb3;
// }

// // Family Details

// .familyDiv {
//   height: 50%;
//   margin-top: 2%;
// }

// .familyFormHeading {
//   margin-top: 5%;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 21px;
//   color: #f15830;
//   display: flex;
//   align-items: center;
// }
// .familyLine {
//   height: 0.1rem;
//   width: 85%;
//   background-color: #f2bfb3;
// }
// .thirdRowLabel {
//   width: 30%;
//   margin-right: 3%;
// }
// // .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1hbvpl3-MuiSvgIcon-root {
// //   color: #acacac;
// // }
// // .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-11zohuh-MuiSvgIcon-root {
// //   color: red;
// // }
// .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
//   font-size: 10px;
// }
// // .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorPrimary.MuiRadio-root.MuiRadio-colorPrimary.css-unwqxc-MuiButtonBase-root-MuiRadio-root {
// //   padding: 1px;
// //   margin-left: 6px;
// // }
// // .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorPrimary.MuiRadio-root.MuiRadio-colorPrimary.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
// //   padding: 1px;
// // }

// // input[type="date"]::-webkit-calendar-picker-indicator {
// //   color: rgba(0, 0, 0, 0);
// //   opacity: 1;
// //   display: block;
// //   background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.72017 18H15.2802C16.5968 18 17.6668 16.93 17.6668 15.6133V3.71998C17.6668 2.40332 16.5968 1.33331 15.2802 1.33331H14.3335V0.666654C14.3335 0.299983 14.0335 0 13.6668 0C13.3001 0 13.0001 0.299983 13.0001 0.666654V1.33331H5.00019V0.666654C5.00019 0.299983 4.70015 0 4.33348 0C3.96681 0 3.66682 0.299983 3.66682 0.666654V1.33331H2.72017C1.40351 1.33331 0.333496 2.40332 0.333496 3.71998V15.6133C0.333496 16.93 1.40351 18 2.72017 18ZM1.66686 3.71998C1.66686 3.14 2.14019 2.66667 2.72017 2.66667H3.66682V3.33333C3.66682 3.7 3.96681 3.99998 4.33348 3.99998C4.70015 3.99998 5.00013 3.7 5.00013 3.33333V2.66667H13.0001V3.33333C13.0001 3.7 13.3001 3.99998 13.6668 3.99998C14.0335 3.99998 14.3335 3.7 14.3335 3.33333V2.66667H15.2801C15.8601 2.66667 16.3334 3.14 16.3334 3.71998V5.66667H1.66686V3.71998ZM1.66686 6.99998H16.3335V15.6133C16.3335 16.1933 15.8602 16.6666 15.2802 16.6666H2.72017C2.14019 16.6666 1.66686 16.1933 1.66686 15.6133V6.99998Z' fill='%23F15830'/%3E%3C/svg%3E%0A");
// //   width: 15px;
// //   height: 15px;
// //   margin-left: 10px;
// //   border-width: thin;
// // }

// input[type="date"]:in-range::-webkit-datetime-edit-year-field,
// input[type="date"]:in-range::-webkit-datetime-edit-month-field,
// input[type="date"]:in-range::-webkit-datetime-edit-day-field,
// input[type="date"]:in-range::-webkit-datetime-edit-text {
//   color: transparent;
// }

// .dobCelebration {
//   width: 28%;
//   margin-left: -3%;
// }
// .date {
//   background: #ffffff;
//   border: 1px solid #acacac;
//   border-radius: 5px;
// }
// .familyFirstRow {
//   width: 34%;
// }
// .familySecondRow {
//   width: 35%;
// }

// .modalButton {
//   height: 38px;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 18px;
//   color: #f15830;
//   background: #ffffff;
//   border: 1px solid #f15830;
//   border-radius: 10px;
// }

// // .addEmployeeButton {
// //     position: absolute;
// //     width: 165px;
// //     height: 39px;

// //     // background: #FFFFFF;
// //     // border: 1px solid #F15830;

// //     // border-radius: 10px;
// //     right: 16%;
// // }

// // .addEmployeeButtonCss {
// //     font-family: 'Roboto';
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 15px;
// //     line-height: 18px;
// //     /* identical to box height */
// //     font-family: 'Roboto';
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 12px;
// //     line-height: 18px;
// //     color: #F15830;

// // }

// .MuiTabs-indicator.css-10d9dml-MuiTabs-indicator {
//   // margin-top: 14%;
//   height: 67px;
//   width: 3px;
//   background-color: #f15830;
// }

// .modal-tab {
//   background-color: #f8e7e3;
// }

// .MuiBox-root .css-zxdg2z {
//   background-color: #e3e7ef;
// }

// //tooltip css

// .tooltip-text {
//   visibility: hidden;
//   position: absolute;
//   z-index: 2;
//   width: 100px;
//   color: #162952;
//   font-size: 12px;
//   background-color: #192733;
//   border-radius: 10px;
//   padding: 10px 15px 10px 15px;
//   background: #ffede9;
//   // box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
// }

// .tooltip-text::before {
//   content: "";
//   position: absolute;
//   transform: rotate(45deg);
//   background-color: #ffede9;
//   padding: 5px;
//   z-index: 1;
// }

// // .hover-text:hover .tooltip-text {
// //   visibility: visible;
// // }

// #top {
//   top: -44px;
//   left: 0%;
//   width: max-content;
// }

// #top::before {
//   top: 80%;
//   left: 10%;
// }

// .hover-text {
//   position: absolute;
// }

// .spinnerClass {
//   position: absolute;
//   left: 50vw;
//   top: 15vw;
//   z-index: 9999999;
//   height: 18vh;
//   width: 18vh;
// }

// .noDisplay {
//   display: none;
//   z-index: 9999999;
// }
// .spinner-border {
//   display: inline-block;
//   width: 4rem;
//   height: 4rem;
//   vertical-align: text-bottom;
//   border: 0.25em solid currentColor;
//   border-right-color: transparent;
//   border-radius: 50%;
//   -webkit-animation: spinner-border 0.75s linear infinite;
//   animation: spinner-border 0.75s linear infinite;
// }

// .makeOpacity {
//   opacity: 0.5;
// }

// .css-1m6qvsi-MuiTabs-root.MuiTabs-root .Mui-selected {
//   color: #f15830;
//   outline: none;
// }

.MuiTablePagination-selectLabel {
  margin-bottom: unset;
}

.MuiTablePagination-displayedRows {
  margin-bottom: unset;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.empCardMain::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.empCardMain {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

.ContainerScrollTm::-webkit-scrollbar {
  // display: none;
  // width: 20px;
}

.ContainerScrollTm {
  overflow-x: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  height: 15vh;
}

.MuiButtonBase-root .MuiButton-root {
  border-color: #fff;
}

.ContainerScrollTm::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.ContainerScrollTm::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.ContainerScrollTm::-webkit-scrollbar-thumb {
  background: #7e7e7e;
  border-radius: 10px;
}

.ContainerScrollTm::-webkit-scrollbar-thumb:hover {
  background: #5f5f5f;
  cursor: pointer;
}

.react-datepicker__day {
  font-weight: normal; /* Decrease boldness here */
}

/* DatePickerStyles.css */
.react-datepicker__day--holidays {
  border-radius: 20px !important; /* Apply border radius to highlighted holidays */
  title: 'unset';
}

.react-datepicker__day--selected {
  border-radius: 20px !important; /* Apply border radius to highlighted holidays */
}

.css-wjsjww-MuiChip-label {
  @media (min-width: 2000px) {
    font-size: calc(12px + 1.6rem) !important;
  }
  @media (min-width: 1500px) and (max-width: 2000px) {
    font-size: calc(12px + 0.8rem) !important;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    font-size: calc(12px + 0.04rem) !important;
  }
  @media (min-width: 600px) and (max-width: 1000px) {
    font-size: calc(12px + 0.004rem) !important;
  }
  @media (max-width: 600px) {
    font-size: calc(12px + 0.0006rem) !important;
  }
}

button:focus {
  outline: none;
}

.shimmer {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 3em;
  margin: 0 auto;
  padding: 0 140px 0 0;
  display: inline;
  margin-bottom: 0;
}

.shimmer p {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.65);
  width: 500px;
  text-align: justify;
  line-height: 1.5em;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
  margin: 10px 0 0 5px;
  padding-top: 10px;
}

.shimmer p a {
  text-decoration: none;
  color: #fff;
}

.shimmer p a:visted {
  color: #fff;
}

.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.applyLeaveHeader {
  text-align: center;
}

// .rotate-scale-up-diag-1 {
// 	-webkit-animation: rotate-scale-up-diag-1 0.7s linear both;
// 	        animation: rotate-scale-up-diag-1 0.7s linear both;
// }

// @-webkit-keyframes rotate-scale-up-diag-1 {
//   0% {
//     -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
//             transform: scale(1) rotate3d(1, 1, 0, 0deg);
//   }
//   50% {
//     -webkit-transform: scale(2) rotate3d(1, 1, 0, -180deg);
//             transform: scale(2) rotate3d(1, 1, 0, -180deg);
//   }
//   100% {
//     -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
//             transform: scale(1) rotate3d(1, 1, 0, -360deg);
//   }
// }
// @keyframes rotate-scale-up-diag-1 {
//   0% {
//     -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
//             transform: scale(1) rotate3d(1, 1, 0, 0deg);
//   }
//   50% {
//     -webkit-transform: scale(2) rotate3d(1, 1, 0, -180deg);
//             transform: scale(2) rotate3d(1, 1, 0, -180deg);
//   }
//   100% {
//     -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
//             transform: scale(1) rotate3d(1, 1, 0, -360deg);
//   }
// }

// .scale-up-center:hover {
// 	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
// 	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
// }

// @-webkit-keyframes scale-up-center {
//   0% {
//     -webkit-transform: scale(0.5);
//             transform: scale(0.5);
//   }
//   100% {
//     -webkit-transform: scale(1);
//             transform: scale(1);
//   }
// }
// @keyframes scale-up-center {
//   0% {
//     -webkit-transform: scale(0.5);
//             transform: scale(0.5);
//   }
//   100% {
//     -webkit-transform: scale(1);
//             transform: scale(1);
//   }
// }
