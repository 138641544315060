* {
  font-family: "Roboto", sans-serif !important;
}

.wrapper {
  width: 100%;
  height: 100vh;
  padding: 20px 20px 0 20px;
  background-color: rgb(227, 231, 239);
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  flex-wrap: wrap;
}

.allEmpHeader {
  font-family: "sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */
  color: #727272;
}

.headerDiv {
  margin-left: 32%;
  width: 100vw;
}

.header1 {
  margin-left: 19.5%;
}

.header2 {
  margin-left: 8%;
}

.header3 {
  margin-left: 10%;
}

.header4 {
  margin-left: 11%;
}

.header5 {
  margin-left: 9.5%;
}

.header6 {
  margin-left: 7%;
}

.headerValue {
  font-family: "sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #162952;
  word-break: break-word;
}

.valueDiv {
  margin-left: 8%;
  margin-bottom: 1%;
  /* margin: 0; */
  padding: 0;
  width: 81%;
  /* width: 100%; */
  word-break: break-word;
}

.allEmployeesHeading {
  font-family: "roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  color: #162952;
}

.addEmployeeButton {
  /* position: absolute; */
  height: 39px;
  /* right: 20%; */
}

.add-action-button {
  color: #f15830 !important;
  border: 1px solid #f15830 !important;
  height: 38px;
  font-size: 14px;
  background: #ffffff !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 10px !important;
  text-transform: unset;
}

.add-action-button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.searchDiv {
}

.searchInput {
  height: 5vh;
  position: relative;
  border: 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #000;
  top: 2vh;
}

@media only screen and (max-width: 800px) {
  .allEmployeesHeading {
    font-size: 15px !important;
  }
  .searchDiv {
    /*float: right;*/
  }
  .searchInput {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    width: 87vw;
    margin-left: 5%;
  }
}
/* @media only screen and (min-width: 320px and max-w){
  .emp_lst_body{
    width: 50vw;
    margin-left: 30%;
  }
} */

.MuiInputBase-input {
  font-size: 13px !important;
}

@media only screen and (min-width: 2000px) {
  .MuiInputBase-input {
    font-size: 13px !important;
  }
}

.employee-list-container {
  overflow-y: scroll;
  height: 85%;
  padding: 10px;
}

@media only screen and (max-width: 425px) {
  .employee-list-container {
    height: 70%;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .employee-list-container {
    height: 80%;
  }
}
