.leave-details-container {
  border: 1px solid #bcbcbc;
  /* border-radius: 10px;
  box-shadow: 5px 5px 10px 0px #0000001a; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 100%;
  justify-content: space-evenly;
}

.info-icon {
  font-size: small !important;
}

.leave-details-heading-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.leave-details-heading {
  display: flex;
  gap: 10px;
  align-items: center;
}

.label-indicator {
  visibility: hidden;
}

.leave-type-indicator {
  height: 10px;
  width: 10px ;
}

.leave-type-heading {
  color: #162952;
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
  overflow-wrap: break-word;
  width: 100%;
}

.leave-details {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.details-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.leave-value {
  color: #162952;
  font-size: 22px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leave-type {
  font-size: 14px;
  font-weight: 400;
  color: #9d9d9d;
}

.comp-off-data {
  color: #a7483c;
  font-size: 14px;
}

.loader-container {
  width: 100%;
  min-height: 40px;
}

@media screen and (max-width: 425px) {
  .leave-type-heading {
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
  }

  .leave-value {
    font-size: calc(var(--fontSize) + 0.0006rem) !important;
  }

  .leave-type {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .leave-details-heading-container {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1999px) {
  .leave-type-indicator {
    height: 15px;
    width: 15px;
  }

  .leave-type-heading {
    font-size: calc(var(--fontSize) + 0.4rem) !important;
  }

  .leave-value {
    font-size: calc(var(--fontSize) + 0.35rem) !important;
  }

  .leave-type,
  .comp-off-data {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
  }
}

@media only screen and (min-width: 2000px) {
  .leave-type-indicator {
    height: 20px;
    width: 20px;
  }

  .leave-type-heading {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .leave-value {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }

  .leave-type,
  .comp-off-data {
    font-size: calc(var(--fontSize) + 0.4rem) !important;
  }
}
