.dashboard {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: 1200px; */
  /* margin: 0px auto; */
}
.header {
  height: 10%;
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  display: flex;
  z-index: 1;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.034);
  /* background-color: transparent; */
  /* backdrop-filter: blur(5px); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerimages {
  /* height: 100%; */
  width: 83%;
  background: transparent;
  /* background-size: cover;
  background-position: center; */
  position: relative;
  /* display: flex; */
  /* flex-direction: row; */
}

.left-overlay-image {
  height: 50%;
  width: 14%;
  background-image: url('../../assets/dashboardImages/techsophyimg.svg');
  background-size: cover;
  background-position: center;
  position: relative;
  left: 0;
  margin-top: 1.2%;
  /* margin-left: 10%; */
}
.header-right {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  float: right;
  /* margin-left: 80%;
  margin-top: 0.5%; */
}
.welcomemsg {
  color: white !important;
}
.username {
  color: white;
}

.content {
  flex: 1.5;
  overflow-y: auto;
}

.scrollimages {
  height: 38%;
  width: 100%;
  position: relative;
}

.background-image {
  height: 100%;
  width: 100%;
  background-image: url('../../assets/dashboardImages/bg.svg');
  background-size: cover;
  background-position: center;
}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.apps-products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 30%; */
  margin-top: -3%;
}

.apps {
  width: 83%;
}
.products {
  /* flex: 1; */
}

.footer {
  height: 5vh;
  padding-bottom: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: rgb(245, 242, 242);
}
.footerleft {
  margin-left: 3%;
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin-top: 1%;
  font-size: 12px;
}
.footerright {
  font-size: 12px;
  margin-left: 42%;
  color: rgb(160, 157, 157);
}
.apps {
  display: flex;
  justify-content: center;
  margin-bottom: '-5%';
}
.clickableDiv {
  /* flex-wrap: wrap; */
  cursor: pointer;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.33%;
  height: 20vh;
  background: linear-gradient(140.93deg, #ffd9cf -12.9%, #ffffff 65.52%);
  box-shadow: 0px 5px 5px 0px #0000002b;
  position: relative;
}

.icon {
  max-width: 35px;
  max-height: 35px;
  margin-bottom: 5px;
}

.nameText {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.allproducts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 83%;
  margin-top: 1%;
  /* margin-top: 1%;
  margin-bottom: 1%; */
  /* margin-left: 10%;
  margin-right: 10%; */
}

.products {
  box-shadow: 0px 5px 5px 0px #0000002b;
  border-radius: 8px;
  /* padding: 10px; */
  margin: 5px;
  width: 100%;
  height: 100%;
}
.proimg {
  /* max-width: 100%;
  max-height: 100%; */
  width: 100%;
}

.protitle {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #3464c1;
  padding: 1.5%;
}

.productsHeading {
  margin-bottom: 1%;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.solutionsDiv {
  display: flex;
  width: 100%;
}

.headerLogo {
  display: flex;
  justify-content: flex-start;
}
