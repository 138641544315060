/* Media query for small screens */

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

/* Media query for medium screens */

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .value {
    font-size: 10px;
  }

  .label {
    font-size: 10px;
  }

  .summaryTitleAsset {
    font-size: 0.4em;
  }

  .empDesignation {
    font-size: 0.6em;
  }

  .summaryContent {
    font-size: 10px;
  }

  .empName {
    font-size: 1em;
  }
}

/* font variable */
:root {
  --fontSize: 12px;
}

.homeGrid {
  display: grid;

  grid-auto-flow: column;

  /* width: 57vw;  */

  overflow-x: auto;

  overflow-y: hidden;

  white-space: nowrap;

  padding: 1%;
}

.homeGrid::-webkit-scrollbar {
  display: none;
}

.homeGrid > .leaves {
  width: 95%;

  height: auto;

  background-color: white;

  border-radius: 10px;

  display: flex;

  /* margin-right: 77px; */

  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/* @media only screen and (max-width:1200px){

  .homeGrid{

    padding-right: 100px;

  }

  

} */

@media only screen and (min-width: 800px) and (max-width: 2000px) {
  /* .cardItemDetails {
    display: flex;
    justify-content: space-between;
  }
  .cardItems{
    width: 48%;
    overflow: hidden;
  } */
}

@media only screen and (max-width: 1140px) {
  .myDivHome {
    margin-left: 50px !important;
  }
  .homeGrid > .leaves {
    /* display: flex; */

    /* flex-wrap: wrap; */

    flex-direction: column;
  }

  .availableLeavesInfo {
    bottom: 1.5em;
  }

  .leavesGraphDiv {
    margin: 1% 1% 1% 10%;
  }

  .leavesContent > .leavesData,
  .leavesContent > .leaveButtonDiv {
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .myDivHome {
    margin-left: 90px !important;
    margin-right: 20px !important;
  }

  .profileCard-outer {
    width: 100% !important;
  }

  .withoutProfileCard {
    width: 100% !important;
  }

  .homeGrid {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .leaves {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .assets {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  .summary {
    padding-top: 10px;
  }

  .summaryContent {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 650px) {
  .myDivHome {
    margin-left: 18% !important;
    margin-right: 10px !important;
  }
  .profileCard-outer {
    width: 100% !important;
  }

  .withoutProfileCard {
    width: 100% !important;
  }

  .homeGrid {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .leaves {
    width: 47% !important;
  }

  .assets {
    width: 100% !important;
  }

  .summaryDiv {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1350px) {
  .leaves > .leavesContent {
    width: 45%;
  }

  .leavesContent > .leavesData,
  .leavesContent > .leavesInfo {
    font-size: 12px;
  }

  .cardSubHeading {
    font-size: 1.2em;
  }

  .availableLeavesCount {
    font-size: 17px;
  }

  .availableText {
    font-size: 13px;
  }

  .leaveButtonDiv {
    margin-top: 10%;
  }
}

.leavesContent {
  background-color: white;

  width: auto;

  height: auto;

  text-align: center;

  margin: 5% 5% 0% 3%;
}

.summaryTitle {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-style: normal;

  font-weight: 500;

  font-size: 17px;

  line-height: 28px;

  color: #162952;

  padding-left: 10%;
}

.summaryTitleAsset {
  font-family: 'Roboto', sans-serif;

  /* font-size: 1.2em; */

  font-weight: 500;

  line-height: 28px;

  letter-spacing: 0em;

  text-align: left;

  color: #162952;
}

.cardItems {
  margin-top: 15px;
}

.empNameBlock {
  background-color: lightgray;
  width: 100%;
}

.empNameLoading {
  background-color: lightgray;
  width: 50px;
}

/* @media only screen and (max-width: 910px) {

  .summary > .summaryTitleAsset {

    font-size: 13px;

  }

}

@media only screen and (max-width: 775px) {

  .summary > .summaryTitleAsset {

    font-size: 10px;

  }

} */

.CircularProgressbar.circularProgressBar {
  width: auto;

  /* margin-top: 11%; */
}

.home-grid-container {
  background-color: #e3e7ef;
  padding: 10px;
  min-height: 101.5vh;
}

.home-grid-container-quick-view {
  min-height: 76vh !important;
  height: 87vh !important;
  padding: 0vh !important;
}

@media only screen and (max-width: 950px) {
  .circularProgressBar > .CircularProgressbar.circularProgressBar {
    margin-bottom: 11px !important;
    display: block;
  }
  .availableLeavesInfo {
    position: initial !important;
  }
}

@media only screen and (max-width: 649px) {
  .leavesData {
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }

  .leavesContent {
    font-size: calc(var(--fontSize) + 0.006rem) !important;
    width: 90%;
  }

  .leavesData {
    margin-bottom: 10px !important;
  }

  .leaveButtonDiv {
    margin-top: 10% !important;
  }

  .leaveManagementLeaves > .leavesContentDetails {
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }

  .cardSubHeading {
    font-weight: bold !important;
    font-size: calc(var(--fontSize) + 0.0008rem) !important;
  }

  .availableLeavesCount {
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
  }
  .availableText {
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
  }

  .leaveButtonCss {
    width: 100px !important;
    height: 30px !important;
  }

  .userLabel {
    font-size: calc(var(--fontSize) + 0.0008rem) !important;
  }
  .userValue {
    font-size: calc(var(--fontSize) + 0.0006rem) !important;
  }

  .empName {
    font-size: calc(var(--fontSize) + 0.0008rem) !important;
  }

  .empDesignation {
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
  }

  .summaryTitleAsset {
    font-weight: bold !important;
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
    margin-bottom: 20px;
  }

  .summaryContent {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .css-lhfsni-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .css-3gs8mn-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .css-1sfrf8a-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .css-114gue0-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .profile-Card {
    height: 100%;
  }

  .cardItemDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(var(--fontSize) + 0.0002rem) !important;
  }

  .generalLabel {
    font-size: calc(var(--fontSize) + 0.0008rem) !important;
  }
}

@media only screen and (min-width: 650px) and (max-width: 999px) {
  .leavesData {
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }

  .leavesContent {
    font-size: calc(var(--fontSize) + 0.006rem) !important;
    width: 90%;
  }

  .leavesData {
    margin-bottom: 10px !important;
  }

  .leaveButtonDiv {
    margin-top: 10% !important;
  }

  .leaveManagementLeaves > .leavesContentDetails {
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }

  .cardSubHeading {
    font-weight: bold !important;
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }

  .availableLeavesCount {
    font-size: calc(var(--fontSize) + 0.004rem) !important;
  }
  .availableText {
    font-size: calc(var(--fontSize) + 0.004rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(var(--fontSize) + 0.004rem) !important;
  }

  .leaveButtonCss {
    width: 100px !important;
    height: 40px !important;
  }

  .userLabel {
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }
  .userValue {
    font-size: calc(var(--fontSize) + 0.006rem) !important;
  }

  .empName {
    font-size: calc(var(--fontSize) + 0.008rem) !important;
  }

  .empDesignation {
    font-size: calc(var(--fontSize) + 0.004rem) !important;
  }

  .summaryTitleAsset {
    font-weight: bold !important;
    font-size: calc(var(--fontSize) + 0.004rem) !important;
    margin-bottom: 20px;
  }

  .summaryContent {
    font-size: calc(var(--fontSize) + 0.002rem) !important;
  }

  .css-lhfsni-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.002rem) !important;
  }

  .css-3gs8mn-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.002rem) !important;
  }

  .css-1sfrf8a-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.002rem) !important;
  }

  .css-114gue0-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.002rem) !important;
  }

  .profile-Card {
    height: 100%;
  }

  .cardItemDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(var(--fontSize) + 0.002rem) !important;
  }

  .generalLabel {
    font-size: calc(var(--fontSize) + 0.08rem) !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1399px) {
  .leavesData {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }

  .leavesContent {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
    width: 90%;
  }

  .leavesData {
    margin-bottom: 15px !important;
  }

  .leaveButtonDiv {
    margin-top: 10% !important;
  }

  .leaveManagementLeaves > .leavesContentDetails {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }

  .cardSubHeading {
    font-weight: bold !important;
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }

  .availableLeavesCount {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }
  .availableText {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }

  .leaveButtonCss {
    width: 100px !important;
    height: 40px !important;
  }

  .userLabel {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }
  .userValue {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .empName {
    font-size: calc(var(--fontSize) + 0.08rem) !important;
  }

  .empDesignation {
    font-size: calc(var(--fontSize) + 0.04rem) !important;
  }

  .summaryTitleAsset {
    font-weight: bold !important;
    font-size: calc(var(--fontSize) + 0.04rem) !important;
    margin-bottom: 20px;
  }

  .summaryContent {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .css-lhfsni-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .css-3gs8mn-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .css-1sfrf8a-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .css-114gue0-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .profile-Card {
    height: 100%;
  }

  .cardItemDetails {
    display: flex;
    justify-content: space-between;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .cardItems {
    width: 47% !important;
    overflow: hidden;
  }

  .generalLabel {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1999px) {
  .avatar img {
    width: 180px !important;
    height: 180px !important;
  }
  .cardItemDetails {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
  }
  .leavesData {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .leavesContent {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .leavesData {
    margin-bottom: 25px !important;
  }

  .cardItems {
    width: 100% !important;
    overflow: hidden;
  }

  .leaveManagementLeaves > .leavesContentDetails {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .cardSubHeading {
    font-size: calc(var(--fontSize) + 1rem) !important;
  }

  .availableLeavesCount {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }
  .availableText {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .leaveButtonCss {
    width: 200px !important;
    height: 60px !important;
  }

  .userLabel {
    font-size: calc(var(--fontSize) + 1rem) !important;
    padding-top: 3% !important;
    padding-bottom: 2% !important;
  }

  .userValue {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .empName {
    font-size: calc(var(--fontSize) + 1.2rem) !important;
  }

  .empDesignation {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
  }

  .summaryTitleAsset {
    font-size: calc(var(--fontSize) + 0.5rem) !important;
    font-weight: bold;
    margin-bottom: 3%;
  }

  .summaryContent {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
    padding-top: 1%;
  }

  .summaryContents {
    height: 60%;
    overflow-y: scroll;
  }

  .css-lhfsni-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }

  .css-3gs8mn-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }

  .css-1sfrf8a-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }

  .css-114gue0-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }

  .homeGrid > .leaves {
    width: 98%;
  }

  .profile-Card {
    height: 100%;
  }

  .cardItemDetails {
    display: flex;
    justify-content: space-between;
  }

  .cardItem {
    margin-bottom: 40px !important;
  }

  .cardSubHeading {
    font-size: calc(var(--fontSize) + 1rem) !important;
    font-weight: bold !important;
  }

  .assets {
    height: 15% !important;
    margin-top: 0 !important;
  }

  .homeGrid {
    display: flex;
    flex-direction: column;
  }

  .leaves {
    margin-bottom: 3%;
  }

  .leavesGraphDiv {
    width: 60% !important;
    margin: 5% auto;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }

  .generalLabel {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }
}

@media only screen and (min-width: 2000px) {
  .avatar img {
    width: 250px !important;
    height: 250px !important;
  }
  .cardItemDetails {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
  }
  .leavesData {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .leavesContent {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .leavesData {
    margin-bottom: 25px !important;
  }

  .leaveManagementLeaves > .leavesContentDetails {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .cardSubHeading {
    font-size: calc(var(--fontSize) + 1.8rem) !important;
  }

  .availableLeavesCount {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }
  .availableText {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .leaveButtonCss {
    width: 250px !important;
    height: 80px !important;
  }

  .userLabel {
    font-size: calc(var(--fontSize) + 1.8rem) !important;
    padding-top: 3% !important;
    padding-bottom: 2% !important;
  }

  .userValue {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .empName {
    font-size: calc(var(--fontSize) + 2rem) !important;
  }

  .empDesignation {
    font-size: calc(var(--fontSize) + 1.6rem) !important;
  }

  .summaryTitleAsset {
    font-size: calc(var(--fontSize) + 1rem) !important;
    font-weight: bold;
    margin-bottom: 3%;
  }

  .summaryContent {
    font-size: calc(var(--fontSize) + 0.8rem) !important;
    padding-top: 1%;
  }

  .summaryContents {
    height: 60%;
    overflow-y: scroll;
  }

  .css-lhfsni-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 1.4rem) !important;
  }

  .css-3gs8mn-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 1.4rem) !important;
  }

  .css-1sfrf8a-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 1.4rem) !important;
  }

  .css-114gue0-MuiButtonBase-root-MuiButton-root {
    font-size: calc(var(--fontSize) + 1.4rem) !important;
  }

  .homeGrid > .leaves {
    width: 98%;
  }

  .profile-Card {
    height: 100%;
  }

  .cardItemDetails {
    display: flex;
    justify-content: space-between;
  }

  .cardItem {
    margin-bottom: 90px !important;
  }

  .cardSubHeading {
    font-size: calc(var(--fontSize) + 1.8rem) !important;
    font-weight: bold !important;
  }

  .assets {
    height: 15% !important;
    margin-top: 0 !important;
  }

  .homeGrid {
    display: flex;
    flex-direction: column;
  }

  .leaves {
    margin-bottom: 3%;
  }

  .leavesGraphDiv {
    width: 50% !important;
    margin: 5% auto;
  }

  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(var(--fontSize) + 1.4rem) !important;
    padding-top: 1% !important;
  }

  .css-1d3osgb-MuiSvgIcon-root {
    width: 2rem !important;
    height: 2rem !important;
  }

  .generalLabel {
    font-size: calc(var(--fontSize) + 1.2rem) !important;
  }

  .home-grid-container {
    padding: 1%;
  }
}

/* @media only screen and (max-width:1024px) {
  .myDivHome {
    margin-left: 40px;
  }

  .CircularProgressbar.circularProgressBar {
    margin-bottom: 15% !important;
  }
} */

.summaryTitleAsset {
  font-weight: bold;
}

.cardSubHeading {
  font-weight: bold !important;
}

.leavesContent > .leavesData {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-style: normal;

  font-weight: 400;

  line-height: 19px;

  text-transform: uppercase;

  color: #9d9d9d;

  text-align: left;

  margin: 5% 3%;

  word-break: break-all;
}

.leaveButtonDiv {
  text-align: right;

  /* width: 13vw; */

  padding-right: 5%;
}

.leaveButtonCss {
  background: linear-gradient(
    15.27deg,
    #ededed 11.88%,

    rgba(255, 255, 255, 0) 90.82%
  );

  filter: drop-shadow(0px 6px 10px rgba(52, 100, 193, 0.26));

  border-radius: 10px;

  width: 120px;

  height: 35px;

  border: 1px solid #ededed !important;

  border-color: #ededed;

  box-shadow: 0px 6px 10px 0px rgba(52, 100, 193, 0.26);
}

.leaveButtonValue {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-style: normal;

  font-weight: 500;

  font-size: 15px;

  line-height: 23px;

  color: #3464c1;

  text-transform: none;

  margin-top: 11%;
}

.boldText {
  color: #162952;
  margin-bottom: 5px;
}

.summary {
  width: 96.85%;

  border-radius: 3%;
}

.summaryDiv {
  display: flex;
}

.cardSubHeading {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  font-style: normal;

  font-weight: 500;

  font-size: 24px;

  line-height: 28px;

  color: #162952;

  margin: 0;

  display: inline; */

  font-family: 'Roboto', sans-serif;

  font-weight: 500;

  line-height: 1;

  letter-spacing: 0em;

  text-align: center;

  color: #162952;
}

.summaryTitle {
  display: flex;

  justify-content: center;
}

.summaryContent {
  /* font-size: 20px; */

  font-weight: 400;

  line-height: 23px;

  letter-spacing: 0em;

  text-align: left;

  font-family: 'Roboto', sans-serif;

  color: #162952;
}

.myDivHome {
  padding: 1% 1%;
  background-color: #e3e7ef;
}

/* @media only screen and (max-width: 1230px) {
  .myDivHome {
    margin-left: 50px;

    margin-right: 38px;
  }
} */

.profileCard-outer {
  width: 35%;
}

.profile-Card {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  padding: 4% 4% 1% 7%;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.avatar {
  margin-left: auto;
  margin-right: auto;
}

.avatar img {
  border: solid 6px transparent;

  border-radius: 50%;

  /* height: 150px; */

  width: 160px;

  height: 160px;

  left: 382px;

  top: 129px;
}

.empName {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-style: normal;

  font-weight: 300;

  /* font-size: 33px; */

  /* line-height: 50px; */

  text-align: center;

  color: #f15830;

  margin: 0% 0 0 0;

  width: 100%;
}

.empDesignation {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-style: normal;

  font-weight: 400;

  /* font-size: 19px; */

  line-height: 22px;

  text-align: center;

  color: #162952;

  margin: 0;

  width: 100%;
}

.textarea {
  margin-top: 11%;
}

.cardItem {
  margin-bottom: 20px;
  margin-top: 15px;
}

.textRow {
  height: 8vh;

  margin-top: 15px;

  display: flex;

  margin-bottom: 18px;
}

.leftData {
  height: auto;

  width: 50%;
}

.rightData {
  width: 50%;

  height: auto;
}

.label {
  color: #9d9d9d;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-weight: 400;

  /* font-size: 15px; */

  line-height: 15px;

  text-align: left;

  margin: 0;

  word-wrap: break-word;
}

.value {
  color: #162952;

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  font-weight: 400;

  /* font-size: 20px; */

  text-align: left;

  margin: 0;

  /* word-wrap: break-word; */

  /* width: 180px; */

  text-overflow: ellipsis;

  overflow: hidden;

  white-space: nowrap;
}

.withoutProfileCard {
  height: auto;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.assets {
  background-color: white;
  height: auto;
  border-radius: 10px;
  padding: 1.5%;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
}

/* .MuiInputAdornment-positionEnd {

  display: none !important;

} */

.assets .summaryContent:last-child {
  border: none;
}

.availableLeavesInfo {
  position: initial;
}

.leavesGraphDiv {
  width: 80%;
}

.userLabel {
  color: #9d9d9d;

  font-family: 'Roboto';

  font-weight: 400;

  font-size: 14px;

  line-height: 17.58px;

  text-align: left;

  margin: 0;

  word-wrap: break-word;
}

.userValue {
  color: #162952;

  font-family: 'Roboto';

  font-weight: 400;

  font-size: 16px;

  text-align: left;

  margin: 0;

  word-wrap: break-word;
}

button:focus {
  outline: unset !important;
}

.modal-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-right: 10px;
  background-color: #ffffff;
}

th,
td {
  min-width: 100px;
}
