@media only screen and (max-width: 1140px) {
  .card-swipe-management {
    margin-left: 50px !important;
  }
}

@media only screen and (max-width: 768px) {
  .card-swipe-management {
    margin-left: 70px !important;
  }
}

@media only screen and (max-width: 425px) {
  .card-swipe-management {
    margin-left: 100px !important;
  }
}

.card-swipe-management {
  margin: 20px;
}
