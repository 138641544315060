.myLeavesContainer {
  border: 1px solid gray;
}

.myLeavesHeading {
  font-weight: bold !important;
}

.title {
  text-align: center;
}

tr {
  height: 40px !important;
}

th {
  max-width: 125px;
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 3px !important;
  text-align: center !important;
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .myLeavesHeading {
    font-size: calc(12px + 0.008rem) !important;
    font-weight: bold !important;
    min-width: 50px !important;
  }

  .myLeavesData {
    font-size: calc(12px + 0.004rem) !important;
    min-width: 50px !important;
  }

  .HolidayListTableContainer {
    max-height: 95% !important;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .myLeavesHeading {
    font-weight: bold !important;
    font-size: calc(12px + 0.2rem) !important;
  }

  .myLeavesData {
    font-size: calc(12px + 0rem) !important;
  }

  .lmsLeaveTabsCards {
    height: 100vh !important;
  }

  .css-6l8dcw {
    margin-bottom: 25px !important;
  }

  .css-6lzzp6 {
    display: flex;
    justify-content: center;
  }

  .holidaysListHeading {
    font-weight: bold !important;
  }

  .HolidayListTableContainer {
    max-height: 95% !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .myLeavesHeading {
    font-weight: bold !important;
    font-size: calc(12px + 0.4rem) !important;
  }

  .myLeavesData {
    font-size: calc(12px + 0.2rem) !important;
  }

  .lmsLeaveTabsCards {
    height: 100% !important;
  }

  .css-6l8dcw {
    margin-bottom: 25px !important;
  }

  .css-6lzzp6 {
    display: flex;
    justify-content: center;
  }

  .holidaysListHeading {
    font-weight: bold !important;
  }

  .HolidayListTableContainer {
    max-height: 95% !important;
  }
}

@media screen and (min-width: 2000px) {
  .myLeavesHeading {
    font-size: calc(12px + 0.8rem) !important;
    font-weight: bold !important;
  }

  .myLeavesData {
    font-size: calc(12px + 0.6rem) !important;
  }

  .css-1fa9u8f-MuiButtonBase-root-MuiButton-root {
    width: 60% !important;
  }

  .css-rorn0c-MuiTableContainer-root {
    max-height: 500px !important;
  }

  .lmsLeaveTabsCards {
    height: 100% !important;
  }

  .css-6l8dcw {
    margin-bottom: 25px !important;
  }

  .css-6lzzp6 {
    display: flex;
    justify-content: center;
  }

  .HolidayListTableContainer {
    max-height: 95% !important;
  }
}

.action-button-class {
  width: 50px;
}

.cancel-button {
  color: #c04a2b;
  background-color: #ededed;
}

.apply-comp-off-button {
  width: 120px;
}
