.upcoming-holiday-container {
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  height: 100%;
  background-size: 100% 100%;
  min-height: 200px;
  background-repeat: no-repeat;
}

.holiday-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif !important;
}

.holiday-heading {
  font-size: 14px;
  font-weight: 300;
  font-family: "Poppins", sans-serif !important;
}

.holiday-date {
  font-size: 19px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.holiday-text {
  font-size: 27px;
  font-weight: 300;
  word-wrap: break-word;
  hyphens: auto;
  white-space: break-spaces;
  font-family: "Poppins", sans-serif !important;
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .holiday-heading{
    font-size: 10px;
  }
  .holiday-date{
    font-size: 14px;
  }
  .holiday-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 2000px) {
  .holiday-heading{
    font-size: 12px;
  }
  .holiday-date{
    font-size: 15px;
  }
  .holiday-text {
    font-size: 18px;
  }
}

@media screen and (min-width: 601px) and (max-width: 999px) {
  .holiday-heading{
    font-size: 7px;
  }
  .holiday-date{
    font-size: 10px;
  }
  .holiday-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .holiday-heading{
    font-size: 7px;
  }
  .holiday-date{
    font-size: 10px;
  }
  .holiday-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 2000px) {
  .holiday-heading{
    font-size: 14px;
  }
  .holiday-date{
    font-size: 17px;
  }
  .holiday-text {
    font-size: 20px;
  }
}
