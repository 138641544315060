.profile-details-card {
  position: relative;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 23px;
}

.profile-avatar {
  display: flex;
  justify-content: center;
  margin: 20px;
  border: none;
}

.profile-avatar img {
  border: solid 6px transparent;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.edit-button-container {
  position: absolute;
  right: 0;
  top: 5px;
}

.profile-avatar-loading-container {
  margin: 20px;
}

.emp-details-container {
  display: flex;
  justify-content: center;
}

.profile-detail-grid-container {
  margin-top: 10px !important;
}

.profile-details-item {
  display: flex;
  flex-direction: column;
}

.profile-details-label {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: #9d9d9d;
}

.profile-details-value {
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  color: #162952;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -3px;
}

.linkedin-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}
.emp-name {
  font-size: 33px;
  font-weight: 300;
  text-transform: capitalize;
  color: #f15830;
}
.emp-location {
  font-size: 16px;
  font-weight: 300;
  text-transform: capitalize;
  color: #162952;
}
.emp-location-container {
  position: absolute;
  left: 12px;
  top: 14px;
}
.emp-designation {
  font-size: 19px;
  text-transform: capitalize;
  color: #162952;
}

.help-documentation {
  /* margin-top: 10px; */
  text-align: left;
  font-weight: bold;
}

.leave-documentation {
  text-align: left;
  font-weight: bold;
}

.leave-outer-box {
  align-self: last baseline;
  display: flex;
  justify-content: flex-end;
}

.manage-documentation-text {
  font-size: 14px;
  color: #3464c1;
  text-decoration: underline;
}

.task-documentation-text {
  font-size: 14px;
  color: #162952;
  text-decoration: underline;
  font-weight: 600;
}

.help-documentation-text {
  font-size: 12px;
  color: #f15830;
}

.help-documentation-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 649px) {
  .profile-details-label {
    font-size: calc(var(--fontSize) + 0rem) !important;
  }

  .profile-details-value {
    font-size: calc(var(--fontSize) + 0rem) !important;
  }

  .emp-name {
    font-size: calc(var(--fontSize) + 0.5rem) !important;
  }
  .emp-designation {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .profile-details-label {
    font-size: calc(var(--fontSize) + 0.02rem) !important;
  }

  .profile-details-value {
    font-size: calc(var(--fontSize) + 0.06rem) !important;
  }

  .emp-name {
    font-size: calc(var(--fontSize) + 0.5rem) !important;
  }
  .emp-designation {
    font-size: calc(var(--fontSize) + 0.3rem) !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1399px) {
  .profile-details-label {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
  }

  .profile-details-value {
    font-size: calc(var(--fontSize) + 0.3rem) !important;
  }

  .emp-name {
    font-size: calc(var(--fontSize) + 0.5rem) !important;
  }
  .emp-designation {
    font-size: calc(var(--fontSize) + 0.3rem) !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1999px) {
  .profile-avatar img {
    width: 120px !important;
    height: 120px !important;
  }

  .profile-details-label {
    font-size: calc(var(--fontSize) + 0.2rem) !important;
  }

  .profile-details-value {
    font-size: calc(var(--fontSize) + 0.3rem) !important;
  }
  .emp-name {
    font-size: calc(var(--fontSize) + 0.5rem) !important;
  }
  .emp-designation {
    font-size: calc(var(--fontSize) + 0.3rem) !important;
  }
}

@media only screen and (min-width: 2000px) {
  .profile-avatar img {
    width: 250px !important;
    height: 250px !important;
  }

  .profile-details-label {
    font-size: calc(var(--fontSize) + 0.5rem) !important;
  }
  .profile-details-value {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }
  .emp-name {
    font-size: calc(var(--fontSize) + 1rem) !important;
  }
  .emp-designation {
    font-size: calc(var(--fontSize) + 0.6rem) !important;
  }
}
