svg > g > g.google-visualization-tooltip { pointer-events: none }
.charts-wrapper {
  height: 100% !important;
  width: 100% !important;
  max-height: 350px !important;
  max-width: 350px !important;
}

@media screen and (max-width: 425px) {
  .charts-wrapper {
    height: 200px !important;
    width: 200px !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .charts-wrapper {
    height: 300px !important;
    width: 300px !important;
  }
}
