.delete {
  width: 6% !important;
}

.delete img {
  width: 25px;
  height: 25px;
  display: inline;
}

.document-upload input {
  display: flex;
  width: 40%;
}

.files {
  display: flex;
  padding-left: 160px;
  padding-top: 6px;
  position: relative;
}

.fileinput p {
  position: absolute;
  top: 96px;
  transform: translate(50px, 50px);
  left: 236px;
  z-index: 0;
  font-style: italic;
}

.doctype {
  background-color: #ffffff !important;
  position: relative;
}

.doctype label {
  width: 130%;
  height: 185px;
  text-align: center;
  background: transparent;
  z-index: 1;
}

.doctype label div {
  display: none;
}

.allfilesEle {
  display: flex;
  width: auto;
  padding: 0px 15px;
  flex-wrap: wrap;
}
.fileEles {
  position: relative;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  margin: 12px;
}

.documentImg {
  height: 35px;
  left: 5px;
  top: 12px;
}

.fileUploadEmp {
  left: 236px;
}
