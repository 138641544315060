:root {
  --gap-size: 4vw;
}

.user-overview-main-container {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  gap: 10px;
}

.overview-container {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 20px;
  font-weight: bold;
  color: #162952;
}
.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.chart-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.actions-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.buttons-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
}

.action-button {
  text-transform: unset !important;
  min-width: 160px;
  color: #3464c1 !important;
  /*border: 1px solid #3464c1 !important;*/
  font-size: 14px;
  background: #ffffff !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  max-height: 40px;
  border-radius: 7px !important;
}

.custom-tooltip {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-label {
  color: #000000;
}

.chart-label {
  word-wrap: break-word;
  max-width: 75px;
}

@media screen and (max-width: 425px) {
  .overview-container {
    flex-direction: column;
  }

  .heading {
    font-size: calc(var(--fontSize) + 0.0004rem) !important;
  }
}

@media screen and (max-width: 1400px) {
  .actions-container {
    flex-direction: column;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .overview-container {
    flex-direction: column;
  }
  .chart-container {
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .overview-container {
    flex-direction: column;
  }
  .chart-container {
    flex-direction: column;
    justify-content: center;
    /* width: 80%; */
  }
  .chart-grid-main-container {
    width: 60%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
}

@media screen and (min-width: 1441px) {
  .heading {
    font-size: calc(var(--fontSize) + 1.4rem) !important;
  }

  .action-button {
    /*font-size: calc(var(--fontSize) + 0.4rem) !important;*/
  }
}
