::-webkit-scrollbar {
  width: 6px;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.grid {
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  /* grid-auto-flow: column; */

  /* overflow-x: auto;

  overflow-y: hidden; */

  /* white-space: nowrap;

  column-gap: 0%; */
}

/* .grid::-webkit-scrollbar {

 

  display: none;

} */

.grid > .leaveManagementLeaves {
  /* margin-left: 3px;

  width: 360px;

  height: auto;

  background-color: white;

  border-radius: 10px;

  display: flex;

  padding: 3%;

  margin-right: 10px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

  width: 49%;

  height: auto;

  background-color: white;

  border-radius: 10px;

  display: flex;

  /* margin-right: 77px; */

  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.leaveManagementInfoWrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0 20% !important;
}

@media only screen and (max-width: 1140px) {
  .myDivLms {
    margin-left: 35px !important;
    margin-right: 10px !important;
  }

  .grid > .leaveManagementLeaves {
    /* display: flex; */

    /* flex-wrap: wrap; */

    padding-top: 10px;

    flex-direction: column;
  }

  .lmsLeavesContent {
    margin: auto !important;
  }

  .leaveManagementInfoWrapper {
    display: flex !important;
    width: 100% !important;
    justify-content: "space-evenly" !important ;
    flex-direction: column !important;
    padding: 0 20% !important;
  }

  .leavesContentDetails {
    background-color: white;

    width: 100% !important;

    height: auto;

    margin: 0 !important;

    display: flex;

    flex-direction: column;

    align-items: center;

    align-self: flex-end;

    justify-content: center;
  }
}

@media only screen and (max-width: 800px) {
  .myDivLms {
    margin-left: 50px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 650px) {
  .leaveManagementLeaves {
    width: 100% !important;
  }

  .circularbarWrapper {
    width: 80%;
    margin: 0 0 10% 15%;
  }
}

@media only screen and (max-width: 1232px) {
  /* .leavesContent > .leavesData,

  .leavesContent > .leaveButtonDiv {

    text-align: center;

  } */

  .leavesData,
  .leaveManagementLeavesInfo {
    font-size: 10px;
  }

  .lmsCardSubHeading {
    font-size: 0.8em;
    font-weight: bold !important;
  }

  .availableLeavesCount {
    font-size: 13px;
  }

  .availableText {
    font-size: 10px;
  }

  .leaveButtonDiv {
    margin-top: 15%;

    margin-bottom: 15%;
  }

  .leavesGraphDiv {
    margin: 1% 1% 25% 10%;
  }
}

.lmsLeavesContent {
  background-color: white;

  width: 50%;

  height: auto;

  text-align: center;

  margin: 1% 4%;
}

.leavesContentDetails {
  background-color: white;

  width: 100% !important;

  height: auto;

  margin: 0 !important;

  display: flex;

  flex-direction: column;

  align-items: center;

  align-self: flex-end;

  justify-content: center;
}

@media only screen and (min-width: 1233px) {
  .leaveManagementLeaves > .leavesContent {
    width: 50%;
  }

  .lmsCardSubHeading {
    font-size: 1em;
    font-weight: bold !important;
  }

  .leaveButtonDiv {
    margin-bottom: 15%;

    margin-top: 39%;
  }

  .availableLeavesCount {
    font-size: 15px;
  }

  .availableText {
    font-size: 12px;
  }

  .leavesGraphDiv {
    margin: 1% 1% 25% 10%;
  }

  .availableLeavesInfo {
    bottom: 0.5em;
  }
}

.summaryTitle {
  font-family: "roboto";

  font-style: normal;

  font-weight: 500;

  font-size: 17px;

  line-height: 28px;

  color: #162952;

  margin: 0;
}

.leavesContentDetails {
  background-color: white;

  width: 50%;

  height: auto;

  margin: 1% 4%;

  display: flex;

  flex-direction: column;

  align-items: center;

  align-self: flex-end;

  padding: 7% 0;

  justify-content: center;
}

.summaryTitleAsset {
  font-family: "roboto";

  font-style: normal;

  font-weight: 500;

  /* font-size: 17px; */

  line-height: 45px;

  color: #162952;

  margin: 0;
}

.CircularProgressbar.circularProgressBar {
  width: auto;

  margin-top: 11%;
}

@media screen and (min-width: 650px) and (max-width: 999px) {
  .leaveManagementLeavesInfo {
    font-size: calc(12px + 0.008rem) !important;
  }

  .leaveManagementLeavesInfo {
    margin-bottom: 10px;
  }

  .leavesContentDetails {
    font-size: calc(12px + 0.008rem) !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-gdh49b-MuiAutocomplete-listbox {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: calc(12px + 0.004rem) !important; /*apply leave */
  }

  .css-7ii8il-MuiFormHelperText-root {
    font-size: calc(12px + 0.002rem) !important; /*apply leave */
  }

  .MuiInputBase-input {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .lmsCardSubHeading {
    font-size: calc(12px + 0.008rem) !important;
    font-weight: bold !important;
  }

  .availableLeavesCount {
    font-size: calc(12px + 0.008rem) !important;
  }
  .availableText {
    font-size: calc(12px + 0.008rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(12px + 0.008rem) !important;
  }

  .leaveManagementleaveButtonCss {
    width: 180px !important;
    height: 30px !important;
  }
}

@media screen and (min-width: 650px) and (max-width: 999px) {
  .leaveManagementLeavesInfo {
    font-size: calc(12px + 0.008rem) !important;
  }

  .leaveManagementLeavesInfo {
    margin-bottom: 10px;
  }

  .leavesContentDetails {
    font-size: calc(12px + 0.008rem) !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-gdh49b-MuiAutocomplete-listbox {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: calc(12px + 0.004rem) !important; /*apply leave */
  }

  .css-7ii8il-MuiFormHelperText-root {
    font-size: calc(12px + 0.002rem) !important; /*apply leave */
  }

  .MuiInputBase-input {
    font-size: calc(12px + 0.008rem) !important; /* apply leave */
  }

  .lmsCardSubHeading {
    font-size: calc(12px + 0.008rem) !important;
    font-weight: bold !important;
  }

  .availableLeavesCount {
    font-size: calc(12px + 0.008rem) !important;
  }
  .availableText {
    font-size: calc(12px + 0.008rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(12px + 0.008rem) !important;
  }

  .leaveManagementleaveButtonCss {
    width: 180px !important;
    height: 30px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .leaveManagementLeavesInfo {
    font-size: calc(12px + 0.08rem) !important;
  }

  .leaveManagementLeavesInfo {
    margin-bottom: 10px;
  }

  .leavesContentDetails {
    font-size: calc(12px + 0.08rem) !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: calc(12px + 0.08rem) !important; /* apply leave */
  }

  .css-gdh49b-MuiAutocomplete-listbox {
    font-size: calc(12px + 0.08rem) !important; /* apply leave */
  }

  .css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
    font-size: calc(12px + 0.08rem) !important; /* apply leave */
  }

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(12px + 0.08rem) !important; /* apply leave */
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: calc(12px + 0.04rem) !important; /*apply leave */
  }

  .css-7ii8il-MuiFormHelperText-root {
    font-size: calc(12px + 0.02rem) !important; /*apply leave */
  }

  .MuiInputBase-input {
    font-size: calc(12px + 0.08rem) !important; /* apply leave */
  }

  .lmsCardSubHeading {
    font-size: calc(12px + 0.08rem) !important;
    font-weight: bold !important;
  }

  .availableLeavesCount {
    font-size: calc(12px + 0.08rem) !important;
  }
  .availableText {
    font-size: calc(12px + 0.08rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(12px + 0.08rem) !important;
  }

  .leaveManagementleaveButtonCss {
    width: 180px !important;
    height: 30px !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 2000px) {
  .leaveManagementLeavesInfo {
    font-size: calc(12px + 0.4rem) !important;
  }

  .leaveManagementLeavesInfo {
    margin-bottom: 20px;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: calc(12px + 0.8rem) !important; /* apply leave */
  }

  .css-gdh49b-MuiAutocomplete-listbox {
    font-size: calc(12px + 0.8rem) !important; /* apply leave */
  }

  .css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
    font-size: calc(12px + 0.8rem) !important; /* apply leave */
  }

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(12px + 0.8rem) !important; /* apply leave */
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: calc(12px + 0.4rem) !important; /*apply leave */
  }

  .css-7ii8il-MuiFormHelperText-root {
    font-size: calc(12px + 0.2rem) !important; /*apply leave */
  }

  .MuiInputBase-input {
    font-size: calc(12px + 0.8rem) !important; /* apply leave */
  }

  .leavesContentDetails {
    font-size: calc(12px + 0.4rem) !important;
  }

  .availableLeavesCount {
    font-size: calc(12px + 0.4rem) !important;
  }
  .availableText {
    font-size: calc(12px + 0.4rem) !important;
  }

  .lmsCardSubHeading {
    font-size: calc(12px + 0.4rem) !important;
    font-weight: bold !important;
  }

  .leaveButtonValue {
    font-size: calc(12px + 0.4rem) !important;
  }

  .leaveManagementleaveButtonCss {
    width: 200px !important;
    height: 50px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .leaveManagementLeavesInfo {
    font-size: calc(12px + 1.6rem) !important;
  }

  .leaveManagementLeavesInfo {
    margin-bottom: 25px !important;
  }

  .lmsCardSubHeading {
    font-size: calc(12px + 1.8rem) !important;
    font-weight: bold !important;
  }

  .css-gdh49b-MuiAutocomplete-listbox {
    font-size: calc(12px + 1.6rem) !important; /* apply leave */
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: calc(12px + 1.6rem) !important; /* apply leave */
  }

  .css-1kp1dnc-MuiButtonBase-root-MuiChip-root {
    font-size: calc(12px + 1.6rem) !important; /* apply leave */
  }

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: calc(12px + 1.6rem) !important; /* apply leave */
  }

  .css-1wc848c-MuiFormHelperText-root {
    font-size: calc(12px + 1.4rem) !important; /*apply leave */
  }

  .css-7ii8il-MuiFormHelperText-root {
    font-size: calc(12px + 0.8rem) !important; /*apply leave */
  }

  .MuiInputBase-input {
    font-size: calc(12px + 1.6rem) !important; /* apply leave */
  }

  .leaveManagementLeaves > .leavesContentDetails {
    font-size: calc(12px + 1.6rem) !important;
  }

  .lmsCardSubHeading {
    font-size: calc(12px + 1.4rem) !important;
    font-weight: bold !important;
  }

  .availableLeavesCount {
    font-size: calc(12px + 1.6rem) !important;
  }
  .availableText {
    font-size: calc(12px + 1.6rem) !important;
  }

  .leaveButtonValue {
    font-size: calc(12px + 1.6rem) !important;
  }

  .leaveManagementleaveButtonCss {
    width: 250px !important;
    height: 80px !important;
  }
}

@media only screen and (max-width: 950px) {
  .circularProgressBar > .CircularProgressbar.circularProgressBar {
    display: block;
    margin-bottom: 0 !important;
  }
}

.leaveManagementLeavesInfo {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  font-style: normal;

  font-weight: 400;

  line-height: 1;

  text-transform: uppercase;

  color: #9d9d9d;

  text-align: right;

  margin: 5% 0%;
}

.leaveButtonDiv {
  text-align: right;

  /* width: 13vw; */

  /* padding-right: 5%; */
}

.leaveManagementleaveButtonCss {
  background: linear-gradient(
    15.27deg,
    #ededed 11.88%,

    rgba(255, 255, 255, 0) 90.82%
  );

  filter: drop-shadow(0px 6px 10px rgba(52, 100, 193, 0.26));

  border-radius: 10px;

  width: 120px;

  height: 35px;

  border: 1px solid;

  border-color: #ededed;

  box-shadow: 0px 6px 10px 0px rgba(52, 100, 193, 0.26);
}

.leaveButtonValue {
  font-family: "Roboto";

  font-style: normal;

  font-weight: 500;

  font-size: 15px;

  line-height: 23px;

  color: #3464c1;

  text-transform: none;

  margin-top: 11%;
}

.boldText {
  color: #162952;
}

.summary {
  width: 96.85%;

  border-radius: 3%;
}

.summaryDiv {
  display: flex;
}

.lmsCardSubHeading {
  font-family: "Roboto";

  font-style: normal;

  font-weight: 500;

  /* line-height: 28px; */

  color: #162952;

  margin: 0;

  display: inline;
}

.summaryTitle {
  display: flex;

  align-items: center;

  padding-left: 0;
}

.summaryContent {
  font-family: "Roboto";

  font-style: normal;

  font-weight: 400;

  font-size: 13px;

  line-height: 15px;

  color: #162952;
}

.myDivLms {
  display: flex;

  justify-content: space-between;

  margin: 0;

  padding: 1% 1%;

  background-color: #e3e7ef;

  height: 100%;
}

.profileCard-outer {
  width: 35%;

  /* margin-left: 5vw; */
}

@media only screen and (max-width: 1230px) {
  .myDivLms {
    margin-left: 50px;

    margin-right: 35px;

    overflow: auto;
  }
}

@media only screen and (min-width: 400px) and (max-width: 650px) {
  .grid {
    display: flex;

    flex-direction: column;
  }

  .leaveManagementLeaves {
    margin-bottom: 10px;
  }

  .lmsLeavesContent {
    width: 100%;
  }

  .myDivLms {
    margin-right: 15px !important;
    margin-left: 80px !important;
  }
}

@media only screen and (max-width: 400px) {
  .grid {
    display: flex;

    flex-direction: column;
  }

  .leaveManagementLeaves {
    width: 100% !important;

    margin-bottom: 10px;
  }

  .lmsLeavesContent {
    width: 100%;
  }

  .circularbarWrapper {
    width: 80%;
    margin: 0 0 10% 15%;
  }

  .myDivLms {
    margin-right: 15px !important;
    margin-left: 90px !important;
  }
}

.profile-Card {
  background-color: white;

  border-radius: 2%;

  text-align: center;

  align-items: center;

  padding: 4% 4% 1% 7%;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.avatar {
  margin-left: auto;

  margin-right: auto;
}

.avatar img {
  border: solid 6px transparent;

  border-radius: 50%;

  /* height: 150px; */

  width: 160px;

  height: 160px;

  left: 382px;

  top: 129px;
}

.empName {
  font-family: "Roboto";

  font-style: normal;

  font-weight: 300;

  font-size: 24px;

  /* line-height: 39px; */

  text-align: center;

  color: #f15830;

  margin: 0% 0 0 0;
}

.empDesignation {
  font-family: "Roboto";

  font-style: normal;

  font-weight: 400;

  font-size: 14px;

  line-height: 22px;

  text-align: center;

  color: #162952;

  margin: 0;
}

.textarea {
  margin-top: 11%;
}

.textRow {
  height: 49px;

  margin-top: 15px;

  display: flex;

  margin-bottom: 18px;
}

.circularbarWrapper {
  width: 80%;
  margin: 0 0 15% 10%;
}

.leftData {
  height: auto;

  width: 50%;
}

.rightData {
  width: 50%;

  height: auto;
}

.label {
  color: #9d9d9d;

  font-family: "Roboto";

  font-weight: 400;

  font-size: 14px;

  line-height: 17.58px;

  text-align: left;

  margin: 0;

  word-wrap: break-word;
}

.value {
  color: #162952;

  font-family: "Roboto";

  font-weight: 400;

  font-size: 16px;

  text-align: left;

  margin: 0;

  word-wrap: break-word;
}

.circularbarWrapper {
  width: 80%;
  margin: 0 0 15% 10%;
}

.LeaveManagementWithoutProfileCard {
  width: 100%;

  height: auto;

  border-radius: 5%;
}

.leaveManagementLeaveButtonDiv {
  margin-top: 5%;
}

.lmsLeaveTabsCards {
  width: 100%;

  background-color: white;

  height: auto;

  margin-top: 3.4vh;

  border-radius: 10px;

  padding: 2% 3% 2% 3%;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* @media only screen and (max-width: 1230px) {
  .myDivLms > .withoutProfileCard > .lmsLeaveTabsCards {
    width: 78vw;

    margin-left: 10px;
  }

  .myDivLms > .withoutProfileCard > .grid {
    width: 78vw;

    margin-left: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .myDivLms > .withoutProfileCard > .lmsLeaveTabsCards {
    width: 71vw;

    margin-left: 10px;
  }

  .myDivLms > .withoutProfileCard > .grid {
    width: 71vw;

    margin-left: 10px;
  }
} */

.years-list-div {
  float: right !important;
}
